import React, { Fragment, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "../../components/button";
import Label from "../../components/label";
import ICInput from "../../components/form/input";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import ICSelect from "../../components/form/selectInput";
import _, { forEach } from "lodash";
import GlobalStyle from "../../components/GlobalStyles";
import { EMAIL_WARNINGS } from "../../constants/common";


Yup.addMethod(Yup.string, "phoneNumberValidation", function (errorMessage) {
    return this.test(`phoneNumberValidationMSG`, errorMessage, function (value) {
        const { path, createError } = this;
        if (value) {
            const stringValue = value.toString();
            return !stringValue.includes("_");
        }
        return true;
    });
});

const PriceListForm = (props) => {

    const [subClinicList, setSubClinicList] = useState([]);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            subClinicId: "Select Clinic",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please enter the first name"),
            lastName: Yup.string().required("Please enter the last name"),
            email: Yup.string()
                .email("Please enter the correct email id")
                .required("Please enter the email id"),
            subClinicId: Yup.string()
                .required("Please select clinic")
                .notOneOf(["Select Clinic"]),
            phoneNumber: Yup.string().required("Please enter the phone number").phoneNumberValidation("Please enter valid phone number"),
        }),
        onSubmit: (values) => {
            props.onFormSubmit(values);

            setTimeout(() => {
                formik.resetForm();
            }, 2000);
        },
    });

    useEffect(() => {
        if (props.comReducerOnlineBooking && props.comReducerOnlineBooking.headClinicData && props.comReducerOnlineBooking.headClinicData.logo) {
            const favicon = document.getElementById("favicon");
            favicon.href = props.comReducerOnlineBooking.headClinicData.logo;

            let subClinics = props.comReducerOnlineBooking &&
                props.comReducerOnlineBooking.headClinicData
                ? props.comReducerOnlineBooking.headClinicData.clinics
                : []
            subClinics.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            setSubClinicList(subClinics);

        }
    }, [props.comReducerOnlineBooking]);

    return (
        <Fragment>
            <GlobalStyle />
            {/* <form
        onSubmit={formik.handleSubmit}
        className=" text-left add-clinic-form"
      /> */}

            {/* </form> */}
            <form onSubmit={formik.handleSubmit} className="text-left row p-0 m-0">
                <div className="col-sm-12 col-xs-12 mt-4">
                    <Label title="First Name" className="font-15 gray" required={true} />
                    <ICInput
                        id="firstName"
                        name="firstName"
                        type="text"
                        label=""
                        placeholder="First Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        error={
                            formik.touched.firstName && formik.errors.firstName ? true : false
                        }
                        helperText={
                            formik.touched.firstName && formik.errors.firstName
                                ? formik.errors.firstName
                                : ""
                        }
                    />
                </div>
                <div className="col-sm-12 col-xs-12  mt-4">
                    <Label title="Last Name" className="font-15 gray" required={true} />
                    <ICInput
                        id="lastName"
                        name="lastName"
                        type="text"
                        label=""
                        placeholder="Last Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        error={
                            formik.touched.lastName && formik.errors.lastName ? true : false
                        }
                        helperText={
                            formik.touched.lastName && formik.errors.lastName
                                ? formik.errors.lastName
                                : ""
                        }
                    />
                </div>
                <div className="col-sm-12 col-xs-12  mt-4">
                    <Label
                        title="Mobile Number"
                        className="font-15 gray"
                        required={true}
                    />
                    <InputMask
                        mask="9999 999 999"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.phoneNumber && formik.errors.phoneNumber
                                ? true
                                : false
                        }
                        helperText={
                            formik.touched.phoneNumber && formik.errors.phoneNumber
                                ? formik.errors.phoneNumber
                                : ""
                        }
                    >
                        {(inputProps) => (
                            <ICInput
                                id="phoneNumber"
                                name="phoneNumber"
                                label=""
                                placeholder="Mobile Number"
                                {...inputProps}
                            />
                        )}
                    </InputMask>
                </div>
                <div className="col-sm-12 col-xs-12  mt-4">
                    <Label
                        title="Email Address"
                        className="font-15 gray"
                        required={true}
                    />
                    <ICInput
                        id="email"
                        name="email"
                        type="email"
                        label=""
                        placeholder="Email Address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={formik.touched.email && formik.errors.email ? true : false}
                        helperText={
                            formik.touched.email && formik.errors.email
                                ? formik.errors.email
                                : ""
                        }
                    />
                    {EMAIL_WARNINGS.some(element => formik.values.email.includes(element)) && formik.touched.email ? (
                        <Label title="Did you mean to type .com? If so, please fix" className="small warning" />
                    ) : (
                        ""
                    )}
                </div>

                <div className="col-sm-12 col-xs-12  mt-3">
                    <Label title="Clinic" className="font-15 gray" required={true} />
                    <ICSelect
                        label=""
                        placeholder="Select Clinic"
                        id="subClinicId"
                        name="subClinicId"
                        useId="_id"
                        useValue="name"
                        items={
                            subClinicList
                        }
                        value={formik.values.subClinicId}
                        disabled={props.isReadOnly}
                        onBlur={formik.handleBlur}
                        handleChange={(val) => {
                            formik.setFieldValue("subClinicId", val);
                        }}
                    />
                    {formik.errors.subClinicId && formik.touched.subClinicId ? (
                        <Label title="Please select clinic" className="small error" />
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-sm-12 mt-4 text-center">
                    <Button type="submit" title="Submit" className="" />
                </div>
            </form>
        </Fragment>
    );
};

export default PriceListForm;

PriceListForm.defaultProps = {
    serverErrorMsg: "",
};

PriceListForm.propTypes = {
    onFormSubmit: PropTypes.func,
    serverErrorMsg: PropTypes.string,
};
