import React, { Fragment, useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import Label from "../../components/label";
import moment from "moment";
import ICInput from "../../components/form/input";
import ICButton from "../../components/iconButton";
import InputMask from "react-input-mask";
import { CONVERT_SERVICE_TYPE, EMAIL_WARNINGS } from "../../constants/common";
import Carousel from "react-multi-carousel";
import MakeAPICall from "../../apiCalls/common";
import FileUploader from "../../components/fileUploader";
import ICIconButton from "../../components/iconButton";
import ICSelect from "../../components/form/selectInput";
import _ from "lodash";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const WEEK_DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

Yup.addMethod(Yup.string, "phoneNumberValidation", function (errorMessage) {
  return this.test(`phoneNumberValidationMSG`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      const stringValue = value.toString();
      return !stringValue.includes("_");
    }
    return true;
  });
});

const BookingConfirmationForm = (props) => {

  const [treatmentImages, setTreatmentImages] = React.useState([]);
  const [apiCall, setApiCall] = React.useState(false);

  console.log("Booking Condition Data: ", props);

  const [times, setTimes] = useState([]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      additionalInfo: "",
      time: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter the first name"),
      lastName: Yup.string().required("Please enter the last name"),
      email: Yup.string()
        .email("Please enter the correct email id")
        .required("Please enter the email id"),
      phoneNumber: Yup.string().required("Please enter the phone number").phoneNumberValidation("Please enter valid phone number"),
      time: Yup.string()
        .notOneOf(["Select Time"])
        .required("Please select the Time"),
    }),
    onSubmit: (values) => {
      values["treatmentImages"] = treatmentImages;
      props.onFormSubmit(values);
    },
  });

  const handleTreatmentImages = (file) => {
    let tmpImages = [...treatmentImages];
    tmpImages.push(file);
    setTreatmentImages(tmpImages);
  };

  const removePhoto = (index) => {
    let imgCpyArr = [...treatmentImages];
    imgCpyArr.splice(index, 1);
    setTreatmentImages(imgCpyArr);
  };

  const callFileUploadApi = (apiCallParam) => {
    setApiCall(apiCallParam);
  };

  useEffect(() => {
    if (props.bookingData && props.bookingData.data && props.bookingData.data && props.bookingData.data.availableSlots) {
      let times = [];

      const treatmentDuration = props.bookingData.data.durationOfConsult;

      // find opening hours of subclinic
      console.log("Need to check the opening hours here: ", props.bookingData.selectedSubClinicData);
      const subClinicOpeningHours = props.bookingData.selectedSubClinicData.openingHours;
      console.log("subClinicOpeningHours: ", subClinicOpeningHours);

      const bookingDate = moment(moment(props.bookingData.selectedDate).format("DD/MM/YYYY"), "DD/MM/YYYY");
      const today = moment();
      const isBookingOnToday = bookingDate.format("DD/MM/YYYY") === today.format("DD/MM/YYYY");
      console.log("bookingDate: ", bookingDate.format("DD/MM/YYYY"));
      console.log("Today: ", today.format("DD/MM/YYYY"));
      console.log("isBookingOnToday: ", isBookingOnToday);

      props.bookingData.data.availableSlots.map((timeSlot, index) => {
        console.log("TimeSlot: ", timeSlot);

        // check the day of timeslot
        const dayNumber = moment(timeSlot.start).day();
        console.log("Day Number: ", dayNumber);

        // we need to compare day name to get the opening hours on that day
        let dayName = WEEK_DAYS[dayNumber];
        console.log("Day Name: ", dayName);
        const openingHoursOnDay = _.find(subClinicOpeningHours, (o) => {
          return o.day === dayName;
        });

        console.log("openingHoursOnDay: ", openingHoursOnDay);
        // if there is timeslot available on that dat then we need to find the opeing hours and see if it's between opening hours or not.
        // if timeslot is between opening hours then we can show otherwise not
        if (openingHoursOnDay) {
          // const isAfterNow = moment(timeSlot.start).isAfter(moment());



          // Convert the periods and timeslot to moment objects
          let start = moment(moment(openingHoursOnDay.periods[0].start).format("HH:mm"), "HH:mm");
          let end = moment(moment(openingHoursOnDay.periods[1].end).format("HH:mm"), "HH:mm");
          let time = moment(moment(timeSlot.start).format("HH:mm"), "HH:mm");

          // let treatmentEndTime = moment(moment(moment(timeSlot.start).format("HH:mm"), "HH:mm").add(treatmentDuration, "minutes"), "HH:mm");
          // console.log("treatmentEndTime: ", treatmentEndTime.format("hh:mm a"));


          console.log("slot end time: ", time.format("hh:mm a"), time.add(treatmentDuration).format("hh:mm a"))

          console.log("Start: ", start.format("hh:mm a"), time.format("hh:mm a"), time.isSameOrAfter(start));
          console.log("End: ", end.format("hh:mm a"), time.format("hh:mm a"), time.isBefore(end));
          console.log("Time: ", time.format("hh:mm a"));
          // console.log("Is Treatment will end before closing? ", treatmentEndTime.format("DD/MM/YYYY hh:mm a"), end.format("DD/MM/YYYY hh:mm a"))

          // Check if the time is between the periods using isBetween() method
          let isBetweenOpeningHours = time.isSameOrAfter(start) && time.isBefore(end);

          // let isTreatmentEndTimeBeforeClosing = treatmentEndTime.isSameOrBefore(end);

          console.log("Time slot is between hours: ", isBetweenOpeningHours);

          // we are checking the time is after current time and slot which we want to display is between sub clinic opeinig hours
          // we havent' decided to use treatment end time is before closing or not yet but it's being calculated in "isTreatmentEndTimeBeforeClosing" variable (which could be true or flase)
          if (isBetweenOpeningHours) {
            // so the timeslot is between opening hours. Now we have to check if it's today and time slot is already passed current time then we will ignore that time
            if (isBookingOnToday) {
              const currentTime = moment();
              const isTimePassed = time.isSameOrBefore(currentTime);
              console.log("isTimePassed: ", isTimePassed, time.format("DD/MM/YYYY h:mm a"), currentTime.format("DD/MM/YYYY h:mm a"));
              if (!isTimePassed) {
                let displayTime = moment(timeSlot.start).format("hh:mm A");
                times.push({ _id: timeSlot.start, val: displayTime });  
              }
            } else {
              let displayTime = moment(timeSlot.start).format("hh:mm A");
              times.push({ _id: timeSlot.start, val: displayTime });
            }
          }
        }
      });
      setTimes(times);
    }
  }, []);

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit} className="row">
        <div className="col-sm-3 mt-3">
          <Label title="Clinic" className="w-100 gray font-15" />
          <Label
            title={props.bookingData.selectedSubClinicName}
            className="w-100"
          />
        </div>

        <div className="col-sm-3 mt-3">
          <Label title="Provider" className="w-100 gray font-15" />
          <Label title={props.bookingData.providerName} className="w-100" />
        </div>

        <div className="col-sm-3 mt-3">
          <Label title="Service" className="w-100 gray font-15" />
          <Label
            title={CONVERT_SERVICE_TYPE[props.bookingData.selectedService]}
            className="w-100"
          />
        </div>

        <div className="col-sm-3 mt-3">
          <Label title="Treatment" className="w-100 gray font-15" />
          <Label
            title={props.bookingData.selectedTreatment}
            className="w-100"
          />
        </div>

        <div className="col-sm-3 mt-3">
          <Label title="Date" className="w-100 gray font-15" />
          <Label
            title={moment(props.bookingData.selectedDate).format("DD/MM/YYYY")}
            className="w-100"
          />
        </div>

        <div className="col-sm-3 mt-3">
          <Label title="Select Time" className="w-100 gray font-15" />
          <ICSelect
            label=""
            placeholder="Select Time"
            id="time"
            items={times}
            useId="_id"
            useValue="val"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.time}
            handleChange={(val) => {
              formik.setFieldValue("time", val);
            }}
            error={formik.touched.time && formik.errors.time ? true : false}
            helperText={
              formik.touched.time && formik.errors.time
                ? formik.errors.time
                : ""
            }
          />
          {formik.errors.time && formik.touched.time ? (
            <Label title="Please select time" className="small error" />
          ) : (
            ""
          )}

        </div>

        <div className="col-sm-3 mt-3">
          <Label title="Duration" className="w-100 gray font-15" />
          <Label
            title={`${props.bookingData.data.durationOfConsult} mins`}
            className="w-100"
          />
        </div>

        <div className="w-100"></div>
        <div className="col-sm-3 mt-4">
          <Label title="First Name" className="font-15 gray" required={true} />
          <ICInput
            id="firstName"
            name="firstName"
            type="text"
            label=""
            placeholder="First Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={
              formik.touched.firstName && formik.errors.firstName ? true : false
            }
            helperText={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ""
            }
          />
        </div>
        <div className="col-sm-3 mt-4">
          <Label title="Last Name" className="font-15 gray" required={true} />
          <ICInput
            id="lastName"
            name="lastName"
            type="text"
            label=""
            placeholder="Last Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={
              formik.touched.lastName && formik.errors.lastName ? true : false
            }
            helperText={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ""
            }
          />
        </div>
        <div className="col-sm-3 mt-4">
          <Label
            title="Email Address"
            className="font-15 gray"
            required={true}
          />
          <ICInput
            id="email"
            name="email"
            type="email"
            label=""
            placeholder="Email Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          {EMAIL_WARNINGS.some(element => formik.values.email.includes(element)) && formik.touched.email ? (
            <Label title="Did you mean to type .com? If so, please fix" className="small warning" />
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-3 mt-4">
          <Label
            title="Mobile Number"
            className="font-15 gray"
            required={true}
          />
          <InputMask
            mask="9999 999 999"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? true
                : false
            }
            helperText={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : ""
            }
          >
            {(inputProps) => (
              <ICInput
                id="phoneNumber"
                name="phoneNumber"
                label=""
                placeholder="Mobile Number"
                {...inputProps}
              />
            )}
          </InputMask>
        </div>
        <div className="col-sm-12 mt-4">
          <Label title="Additional Information" className="gray font-15" />
          <ICInput
            id="additionalInfo"
            name="additionalInfo"
            type="text"
            label=""
            multiline
            className="text-area"
            rows={"3"}
            placeholder="Additional Information"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.additionalInfo}
          />
        </div>

        <div className="col-sm-12 mt-4">
          <p>A $50 deposit is required to secure treatment bookings, serving as a non-refundable credit towards the total cost of your treatment.</p>
          <p>A team member will call you to secure your deposit and confirm your treatment booking.</p>
          <p>Deposits are not refunded for no-shows or same day cancellations.</p>
          <p>No deposits are required for initial consults to receive free comprehensive facial and/or body assessments and treatment plans.</p>
          <p>If you decide to proceed with a treatment during your initial consult, a $50 deposit will be taken in-clinic prior to commencing.</p>
        </div>

        {/* <div className="col-sm-6 mt-4">
          <Label title="Add Photos" className="font-15 gray" />
          <FileUploader
            displayImg={false}
            folderName="treatmentPhotos"
            selectedFile={(file) => handleTreatmentImages(file)}
            callFileUploadApi={callFileUploadApi}
          />
        </div> */}

        <div className="col-sm-12 mt-1">
          <Carousel
            swipeable={true}
            draggable={false}
            responsive={responsive}
            transitionDuration={200}
            arrows={true}
            showDots={false}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {treatmentImages.map((element, index) => {
              return (
                <div key={index}>
                  <img
                    src={element}
                    alt="treatment-before-img"
                    className="treatment-before-img"
                  />
                  <ICIconButton
                    icon="fas fa-times"
                    title=""
                    className="only-icon delete-btn"
                    clickEvent={() => removePhoto(index)}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className="col-sm-12 text-center mt-1">
          <ICButton type="submit" className="insta-button-primary" />
        </div>
      </form>
      <MakeAPICall apiParam={apiCall} />
    </Fragment>
  );
};

export default BookingConfirmationForm;
