/* 
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import InstaLabel from "../../components/label";
import appRoutes from "../../routes/appRoutes";
import Anchor from "../../components/anchor";

class Footer extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      // privacyPolicy: props.comReducerOnlineBooking.constantsData && props.comReducerOnlineBooking.constantsData.adminPrivacyPolicy ? props.comReducerOnlineBooking.constantsData.adminPrivacyPolicy : "",
      privacyPolicy: "https://instant-touch-live.s3.amazonaws.com/documents/1698361730201Instant%20Touch%20Privacy%20Policy.pdf"
    }
  }
  
  render() {
    return (
      <div className="container-fluid footer">
        <div className="row">
          <div className="col-sm-12 pt-2 pb-2 bg-white border-top">
            <InstaLabel title="Instant Touch" className="ex-small gray" />
            <InstaLabel title="&reg;" className="gray ex-small" />
            <InstaLabel title="brought to you by Instant Consult" className="ex-small gray ml-1" />
            <InstaLabel title="&trade;" className="gray ex-small" />
            <Anchor title="Privacy Policy" className="pl-2 text-decoration-none font-12 text-primary cursor-pointer font-weight-normal" target="_blank" href={this.state.privacyPolicy}/>
          </div>
        </div>
      </div>
    );
  }
}

// export default Footer;
const mapStateToProps = state => ({
  comReducerOnlineBooking: state.comReducerOnlineBooking
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
