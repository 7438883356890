import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CustomModal = props => (
  <Modal
    isOpen={props.openModal}
    toggle={props.cancelEvent}
    className={"custom-modal-container " + props.className}
    size={props.size}
  >
    <ModalHeader toggle={props.cancelEvent}>{props.title}</ModalHeader>
    <ModalBody>{props.modalBodyContent}</ModalBody>
    <ModalFooter>
      {!props.noFooterButtons && (
        <React.Fragment>
          {!props.useOneButton && (
            <React.Fragment>
              <button
                className="insta-button-primary insta-modal-btn gray"
                onClick={props.okayEvent}
              >
                {props.okayText}
              </button>
              &nbsp;&nbsp;
            </React.Fragment>
          )}
          <button className="insta-button-primary gray" onClick={props.cancelEvent}>
            {props.cancelText}
          </button>
        </React.Fragment>
      )}
    </ModalFooter>
  </Modal>
);

export default CustomModal;

CustomModal.defaultProps = {
  okayText: "OKAY",
  cancelText: "CANCEL",
  modalBodyContent: "",
  openModal: false,
  className: "",
  useOneButton: false,
  noFooterButtons: false
};

CustomModal.propTypes = {
  okayText: PropTypes.string,
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.any,
  okayEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  openModal: PropTypes.bool,
  className: PropTypes.string,
  useOneButton: PropTypes.bool,
  noFooterButtons: PropTypes.bool
};
