import React from "react";
import TextField from "@material-ui/core/TextField";

const ICInput = props => (
  <TextField
    InputLabelProps={{
      shrink: true
    }}
    {...props}
    className={"ic-input " + props.className}
  />
);

export default ICInput;
