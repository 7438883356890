import React, { Component, Fragment, createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setHeadClinicData, setConstantData } from "../../reduxStore/actions/commonAction";
import OnlineBookingForm from "../../containers/OnlineBookingForm/OnlineBookingForm";
import BookingAvailabilityList from "../../containers/OnlineBookingForm/BookingAvailabilityList";
import PublicLayout from "../../containers/Layout/PublicLayout";
import {
    getHeadClinicAllData,
    checkOnlineBookingAvailibilityAPI,
    getConstantsApi,
    getHeadClinicAllDataFromPriceListURL,
    submitPriceListFormByPatientAPI
} from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import CustomPopUp from "../../components/modalProps";
import Label from "../../components/label";
import moment from "moment";
import _ from "lodash";
import Loader from "../../components/loader";
import GlobalStyle from "../../components/GlobalStyles";
import PriceListForm from "../../containers/CheckInPriceListForm/PriceListForm";

class CheckInPriceListPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            apiCall: false, // api call
            apiCall2: false, // parallel api call
            loading: true,
            globalStyleLoaded: false,
            isInvalidURL: false, // if head clinic not found
            selectedSubData: null,
            isSuccessSubmitForm: false
        };
    }

    componentApiCall = (data) => {
        this.setState({
            apiCall: data,
        });
    };


    componentDidMount() {
        // console.log("In Online Booking : ", window.location.href);
        const url = window.location.href.split('?')[0];
        console.log("URLLLL: ", url);
        let dataToGet = {
            priceListURL: url,
        };
        const apiCallParam = {
            reqType: "POST",
            reqURL: getHeadClinicAllDataFromPriceListURL,
            dataObj: dataToGet,
            params: {},
            headers: {},
            successCallback: async (res) => {
                // console.log("Got the clinic data ", res);
                if (!res.data.length) {
                    this.setState({
                        isInvalidURL: true,
                    });
                } else {
                    await this.props.setHeadClinicData(res.data[0]);
                }
                this.setState({
                    loading: false,
                });
            },
            failureCallback: (err) => {
                console.log("Something went wrong. Please try again ", err);
                this.setState({
                    loading: false,
                });
            },
            showPopUp: false,
        };
        this.componentApiCall(apiCallParam);

        this.getContants();

        document.title = 'Instant Touch® - Check In & Receive Price List';

    }

    getContants() {
        const apiCallParam = {
            reqType: "GET",
            reqURL: getConstantsApi,
            dataObj: {},
            params: {},
            headers: {},
            successCallback: async (res) => {
                console.log("Got the constant data: ", res);
                if (res.data && res.data[0]) {
                    await this.props.setConstantData(res.data[0]);
                }
            },
            failureCallback: (err) => {

            },
            showPopUp: false,
        };
        this.setState({
            apiCall2: apiCallParam,
        });
    }

    handleGloblaStyleLoaded = (val) => {
        this.setState({
            globalStyleLoaded: val,
        });
    };

    handleCheckInPriceListForm = (formData) => {

        console.log("User wants to check in: ", formData);

        let subClinicData = _.find(this.props.comReducerOnlineBooking.headClinicData.clinics, (o) => { return o._id === formData.subClinicId });
        if (subClinicData) {
            this.setState({
                selectedSubData: subClinicData
            })
        };

        const dataToSend = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            subClinicId: formData.subClinicId,
            headClinicId: this.props.comReducerOnlineBooking.headClinicData._id
        }

        const apiCallParam = {
            reqType: "POST",
            reqURL: submitPriceListFormByPatientAPI,
            dataObj: dataToSend,
            params: {},
            headers: {},
            successCallback: async (res) => {

                this.setState({
                    isSuccessSubmitForm: true
                });
            },
            failureCallback: (err) => {
                console.log("Something went wrong. Please try again ", err);
                this.setState({
                    loading: false,
                });
            },
            showPopUp: false,
        };
        this.componentApiCall(apiCallParam);
    };

    render() {
        return (
            <PublicLayout>
                <GlobalStyle onLoad={this.handleGloblaStyleLoaded} />
                <Loader loading={this.state.loading} />
                {this.state.globalStyleLoaded && (
                    <Fragment>
                        {this.props.comReducerOnlineBooking?.headClinicData && (
                            <div className="p-5 mt-5 container clinic-online-booking">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3">
                                        <div className="row p-3 pb-4 mb-5 card">
                                            <div className="col-sm-12 border-bottom">
                                                <Label title="Check In & Receive Price List" className="big ml-3" />
                                            </div>
                                            <div className="col-sm-12 mt-4">
                                                <div className="row p-0 m-0">
                                                    {this.props.comReducerOnlineBooking?.headClinicData && (
                                                        <PriceListForm onFormSubmit={this.handleCheckInPriceListForm} comReducerOnlineBooking={this.props.comReducerOnlineBooking} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <MakeAPICall apiParam={this.state.apiCall} />
                        <MakeAPICall apiParam={this.state.apiCall2} />
                        <CustomPopUp
                            openModal={this.state.isInvalidURL}
                            title="Instant Touch"
                            cancelText="Okay"
                            cancelEvent={() => {
                                console.log("Clinic Data Not Found!");
                            }}
                            useOneButton={true}
                            modalBodyContent={
                                <div className="container-fluid">
                                    <Label title="Invalid Link" />
                                </div>
                            }
                        />
                        <CustomPopUp
                            openModal={this.state.isSuccessSubmitForm}
                            title={this.props.comReducerOnlineBooking.headClinicData && this.props.comReducerOnlineBooking.headClinicData.clinicName ? this.props.comReducerOnlineBooking.headClinicData.clinicName : ''}
                            cancelText="Okay"
                            cancelEvent={() => {
                                this.setState({
                                    isSuccessSubmitForm: false
                                });
                                if (this.props.comReducerOnlineBooking.headClinicData && this.props.comReducerOnlineBooking.headClinicData.redirectURL) {
                                    window.open(this.props.comReducerOnlineBooking.headClinicData.redirectURL);
                                }
                                console.log("Okay")
                            }}
                            useOneButton={true}
                            modalBodyContent={
                                <div className="container-fluid">
                                    <Label title={`Thank you for checking in at ${this.state.selectedSubData ? this.state.selectedSubData.name : ''}`} />
                                    <br />
                                    <Label title={`Price list has been emailed to your nominated email address`} />
                                </div>
                            }
                        />
                    </Fragment>
                )}
            </PublicLayout>
        );
    }
}

// export default withRouter(OnlineBooking);
const mapStateToProps = (state) => ({
    comReducerOnlineBooking: state.comReducerOnlineBooking,
});

const mapDispatchToProps = (dispatch) => ({
    setHeadClinicData: (data) => dispatch(setHeadClinicData(data)),
    setConstantData: (data) => dispatch(setConstantData(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CheckInPriceListPage));
