import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CustomModal = props => (
  <Modal
    isOpen={props.openModal}
    toggle={props.backdrop === false ? () => {} : props.cancelEvent}
    className={"custom-modal-container " + props.className}
    backdrop={props.backdrop || true}
  >
    <ModalHeader toggle={props.cancelEvent}>{props.title}</ModalHeader>
    <ModalBody>
      <div dangerouslySetInnerHTML={{ __html: props.modalBodyContent }}></div>
    </ModalBody>
    {props.noButtons ? (
      ""
    ) : (
      <ModalFooter>
        {!props.useOneButton && (
          <React.Fragment>
            <button className="insta-button-primary gray" onClick={props.okayEvent}>
              {props.okayText}
            </button>
            &nbsp;&nbsp;
          </React.Fragment>
        )}
        <button className="insta-button-primary gray" onClick={props.cancelEvent}>
          {props.cancelText}
        </button>
      </ModalFooter>
    )}
  </Modal>
);

export default CustomModal;

CustomModal.defaultProps = {
  okayText: "OKAY",
  cancelText: "CANCEL",
  modalBodyContent: "",
  openModal: false,
  className: "",
  useOneButton: false,
  noButtons: false
};

CustomModal.propTypes = {
  okayText: PropTypes.string,
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.string,
  okayEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  openModal: PropTypes.bool,
  className: PropTypes.string,
  useOneButton: PropTypes.bool,
  noButtons: PropTypes.bool
};
