import React, { Component, Fragment, createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setHeadClinicData, setConstantData } from "../../reduxStore/actions/commonAction";
import OnlineBookingForm from "../../containers/OnlineBookingForm/OnlineBookingForm";
// import BookingAvailabilityList from "../../containers/OnlineBookingForm/BookingAvailabilityList";
import BookingAvailabilityListByNurse from "../../containers/OnlineBookingForm/BookingAvailabilityListByNurse";
import PublicLayout from "../../containers/Layout/PublicLayout";
import {
  getHeadClinicAllData,
  checkOnlineBookingAvailibilityAPI,
  getConstantsApi,
  checkNurseAvabilityOnlineBookingAPI
} from "../../apiRoutes";
import MakeAPICall from "../../apiCalls/common";
import CustomPopUp from "../../components/modalProps";
import Label from "../../components/label";
import moment from "moment";
import _ from "lodash";
import Loader from "../../components/loader";
import GlobalStyle from "../../components/GlobalStyles";
import { SERVICE_TYPES } from "../../constants/common";

class OnlineBooking extends Component {
  WEEK_DAYS = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  constructor(props) {
    super(props);

    this.state = {
      apiCall: false, // api call
      apiCall2: false, // api call parallel
      loading: true,
      globalStyleLoaded: false,
      isInvalidURL: false, // if head clinic not found
      bookedAppointmentData: null, // all booked appointment data from database
      selectedNurseData: null, // user selected nurse
      allProviders: [], // all providers/ all nurse
      isAllProvidersOptionSelected: false, // if user select the all providers option from the dropdown
      selectedService: null, // user selected service
      selectedTreatment: null, // user selected treatment
      userSelectedDatesInNumber: [], // user selected day number
      allAvailableTimingsForDay: [], // all timings of subclinic (opening-closing)with 30 min interval
      selectedSubClinic: null, // selected sub clinic data
      preferedTime: "", // patient prefered time
      bookingAvailibilityRef: createRef(),
      nurseShifsList: [], // nurse shift start and end times so you can remove those timeslots in which nurse is not working
      arrangedShiftsData: [], // all shifts data as per new requirements (not showing all slots here but it will be shown when confirming)
      sortedDates: [], // new table usess this sorted dates now
      treatmentDuration: 30, // treatment duration based on what user selects
      isCallClinicModal: false, // isCallClinicModal - to show the clinic phone number
    };
  }

  treatmentDuration() {
    // console.log("this.props.comReducerOnlineBooking.headClinicData: ", this.props.comReducerOnlineBooking.headClinicData);
    // console.log("this.state.selectedTreatment: ", this.state.selectedTreatment);
    let totalTreatmentDuration = 0;

    const selectedTreatmentName = this.state.selectedTreatment;
    const treatmentTypes = this.props.comReducerOnlineBooking.headClinicData.treatmentTypes;
    // console.log("selectedTreatmentName: ", selectedTreatmentName);
    // console.log("treatmentTypes: ", treatmentTypes);

    if (selectedTreatmentName) {
      const foundTreatment = treatmentTypes.find(
        (treatmentType) => treatmentType.name === selectedTreatmentName
      );
      totalTreatmentDuration = foundTreatment && foundTreatment.defaultDuration ? foundTreatment.defaultDuration : 0;
    }

    let durationOfConsult = 15;
    if (totalTreatmentDuration > 0) {
      durationOfConsult = totalTreatmentDuration;
    } else if (this.state.selectedService === SERVICE_TYPES.INITIAL_CONSULT) {
      // Vicky => this needs to removed after every clinic has default duration
      durationOfConsult = 30;
    } else if (this.state.selectedService === SERVICE_TYPES.TREATMENT) {
      durationOfConsult = 60;
    } else if (this.state.selectedService === SERVICE_TYPES.POST_TREATMENT) {
      durationOfConsult = 15;
    }

    return durationOfConsult;
  }

  // checkAvailableTimesForBooking = (formData) => {

  //   this.setState({
  //     userSelectedDatesInNumber: formData.preferedDays,
  //     preferedTime: formData.timing,
  //     selectedService: formData.serviceType,
  //     selectedTreatment: formData.treatment,
  //     loading: true,
  //   });
  //   let findSelectedSubClinic = _.find(
  //     this.props.comReducerOnlineBooking.headClinicData.clinics,
  //     (o) => {
  //       return o._id === formData.subClinic;
  //     }
  //   );
  //   if (findSelectedSubClinic) {
  //     this.setState({
  //       selectedSubClinic: findSelectedSubClinic,
  //     });
  //   }

  //   const apiCallParam = {
  //     reqType: "POST",
  //     reqURL: checkOnlineBookingAvailibilityAPI,
  //     dataObj: formData,
  //     params: {},
  //     headers: {},
  //     successCallback: async (res) => {

  //       this.setState({
  //         nurseShifsList: res.data.nurseShifsList,
  //         bookedAppointmentData: res.data.getAvailableTimings.bookingData,
  //         allAvailableTimingsForDay: [], // just resetting the state data here so we can change 
  //         loading: false,
  //       });
  //       this.calculateIntervalTimings(res.data.getAvailableTimings.selectedDates);
  //     },
  //     failureCallback: (err) => {
  //       console.log("Something went wrong. Please try again ", err);
  //       this.setState({
  //         loading: false,
  //       });
  //     },
  //     showPopUp: false,
  //   };
  //   this.componentApiCall(apiCallParam);
  // };

  resetData = () => {
    this.setState({
      bookedAppointmentData: null, // all booked appointment data from database
      selectedNurseData: null, // user selected nurse
      allProviders: [], // all providers/ all nurse
      isAllProvidersOptionSelected: false, // if user select the all providers option from the dropdown
      selectedService: null, // user selected service
      selectedTreatment: null, // user selected treatment
      userSelectedDatesInNumber: [], // user selected day number
      allAvailableTimingsForDay: [], // all timings of subclinic (opening-closing)with 30 min interval
      selectedSubClinic: null, // selected sub clinic data
      preferedTime: "", // patient prefered time
      bookingAvailibilityRef: createRef(),
      nurseShifsList: [], // nurse shift start and end times so you can remove those timeslots in which nurse is not working
      arrangedShiftsData: [], // all shifts data as per new requirements (not showing all slots here but it will be shown when confirming)
      sortedDates: [], // new table usess this sorted dates now
      treatmentDuration: 30, // treatment duration based on what user selects
      isCallClinicModal: false, // isCallClinicModal - to show the clinic phone number
    });
  };

  findNursesAvabilities = (formData) => {

    this.resetData();

    this.setState({
      userSelectedDatesInNumber: formData.preferedDays,
      selectedService: formData.serviceType,
      selectedTreatment: formData.treatment,
      loading: true,
    });
    let findSelectedSubClinic = _.find(
      this.props.comReducerOnlineBooking.headClinicData.clinics,
      (o) => {
        return o._id === formData.subClinic;
      }
    );
    if (findSelectedSubClinic) {
      this.setState({
        selectedSubClinic: findSelectedSubClinic,
      });
    }

    const apiCallParam = {
      reqType: "POST",
      reqURL: checkNurseAvabilityOnlineBookingAPI,
      dataObj: formData,
      params: {},
      headers: {},
      successCallback: async (res) => {
        console.log("Nurse Avaibility is here: ", res);

        const selectedDates = res.data.selectedDates;
        let arrangedShiftData = [];
        for (let i = 0; i < selectedDates.length; i++) {
          arrangedShiftData.push({
            selectedDate: selectedDates[i],
            data: res.data.arrangedNurseSlotDataForFrontEndOnly[selectedDates[i]]
          })
        };

        const treatmentDuration = this.treatmentDuration();

        this.setState({
          // nurseShifsList: res.data.nurseShifsList,
          // bookedAppointmentData: res.data.getAvailableTimings.bookingData,
          // allAvailableTimingsForDay: [], // just resetting the state data here so we can change
          arrangedShiftsData: arrangedShiftData,
          sortedDates: res.data.selectedDates,
          loading: false,
          treatmentDuration: treatmentDuration
        });
        // this.calculateIntervalTimings(res.data.getAvailableTimings.selectedDates);
      },
      failureCallback: (err) => {
        console.log("Something went wrong. Please try again ", err);
        this.setState({
          loading: false,
        });
      },
      showPopUp: false,
    };
    this.componentApiCall(apiCallParam);
  };

  componentApiCall = (data) => {
    this.setState({
      apiCall: data,
    });
  };

  calculateIntervalTimings = (selectedDates) => {
    const clinicOpeningHours = this.state.selectedSubClinic.openingHours;
    // console.log(
    //   "this.state.userSelectedDatesInNumber -- ",
    //   this.state.userSelectedDatesInNumber
    // );
    let selectedDatesArrCount = 0;
    this.state.userSelectedDatesInNumber.map((dayNumber, index) => {
      let dayName = this.WEEK_DAYS[dayNumber - 1],
        openingHoursOnDay = _.find(clinicOpeningHours, (o) => {
          return o.day === dayName;
        });
      // console.log("dayName openingHoutsOnDay : ", dayName, openingHoursOnDay);
      if (openingHoursOnDay) {
        for (let i = 0; i < 2; i++) {
          // console.log(
          //   "start end selectedDate dayName, -- ",
          //   openingHoursOnDay.periods[0].start,
          //   openingHoursOnDay.periods[1].end,
          //   selectedDates[selectedDatesArrCount],
          //   dayName,
          //   selectedDates,
          //   index,
          //   selectedDatesArrCount
          // );
          this.addIntervalTimings(
            openingHoursOnDay.periods[0].start,
            openingHoursOnDay.periods[1].end,
            selectedDates[selectedDatesArrCount],
            dayName
          );
          selectedDatesArrCount++;
        }
        let executeScroll = this.state.bookingAvailibilityRef.current.scrollIntoView();
        // console.log("Scroll Done: ", executeScroll);
      }
    });



    // console.log("===== All times: ", this.state.allAvailableTimingsForDay);
  };

  addIntervalTimings = (openingTime, closingTime, selectedDate, dayName) => {
    const currentDate = moment(selectedDate).format("DD/MM/YYYY");

    // each treatment has their own treatment duration. We will use this to figureout the timeslot.
    const defaultDuration = this.treatmentDuration();
    console.log("defaultDuration: ", defaultDuration);

    const selectedDateBookingData = _.filter(
      this.state.bookedAppointmentData,
      (o) => {
        return moment(o.scheduledDate).format("DD/MM/YYYY") === currentDate;
      }
    );

    const data = {
      slotInterval: 15,
      openTime: moment(openingTime).format("HH:mm"),
      closeTime: moment(closingTime).format("HH:mm"),
    };

    //Format the time
    const startTime = moment(data.openTime, "HH:mm");
    const endTime = moment(data.closeTime, "HH:mm");

    let patientPreferenceTimeStart = startTime,
      patientPreferenceEndTime = endTime;

    if (this.state.preferedTime === "morning") {
      patientPreferenceEndTime = moment("11:59", "HH:mm");
    } else if (this.state.preferedTime === "afternoon") {
      patientPreferenceTimeStart = moment("12:00", "HH:mm");
      patientPreferenceEndTime = moment("16:59", "HH:mm");
    } else if (this.state.preferedTime === "evening") {
      patientPreferenceTimeStart = moment("17:00", "HH:mm");
    }

    //Times
    let allTimes = [];

    while (startTime < endTime) {
      let isTimeSuitable =
        patientPreferenceTimeStart <= startTime &&
        patientPreferenceEndTime >= startTime;
      let startTimeBooked = selectedDateBookingData.filter((bookingData) => {
        // we are substracting defaultDuration because we don't want to overlap the bookings. On Backend it's checking perfect. On Front-end we will not show the slot which comes in between the booking according to treatment duration.
        let bookingStartTime = moment(bookingData.scheduledStartTime).subtract(defaultDuration, "minutes").format(
            "HH:mm"
          ),
          bookingEndTime = moment(bookingData.scheduledEndTime).format("HH:mm");
        let isBookingTimeInBetween =
          moment(bookingStartTime, "HH:mm") < startTime &&
          moment(bookingEndTime, "HH:mm") > startTime;

        // console.log("-----------------------");
        // console.log("Default Duration: ", defaultDuration);
        // console.log("Booking Data Treatment Duration: ", bookingData.duration);
        // console.log("startTime: ", startTime.format("HH:mm"));
        // console.log("bookingStartTime: ", bookingStartTime);
        // console.log("bookingEndTime: ", bookingEndTime);
        // console.log(`moment(bookingStartTime, "HH:mm") <= startTime: `, moment(bookingStartTime, "HH:mm") <= startTime);
        // console.log(`moment(bookingEndTime, "HH:mm") > startTime: `, moment(bookingEndTime, "HH:mm") > startTime);
        // console.log(`isBookingTimeInBetween: `, isBookingTimeInBetween);

        // let isBookingTimeInTreatmentDurationBetween =
        //   moment(bookingStartTime, "HH:mm") <= startTime &&
        //   moment(bookingEndTime, "HH:mm") > startTime;

        if (isBookingTimeInBetween) return bookingData;
      });
      if (isTimeSuitable) {
        let selectedTimeBookedLenMatch = (startTimeBooked.length === this.state.allProviders.length);

        if(!startTimeBooked.length || (this.state.isAllProvidersOptionSelected && !selectedTimeBookedLenMatch)){
          let currentStartTime = moment(
            currentDate + " " + startTime.format("HH:mm"),
            "DD/MM/YYYY HH:mm"
          ).toISOString();
          let availableNurseData = this.findAvailableNurseData(startTime, startTimeBooked);
          allTimes.push({ time: currentStartTime , nurses: availableNurseData } );
        }
      }
      //Add interval of 30 minutes
      startTime.add(data.slotInterval, "minutes");
    }

    let allAvailableTimingsForDay = [...this.state.allAvailableTimingsForDay];
    allAvailableTimingsForDay.push({
      dayName: dayName, // MON/TUE/...
      times: allTimes, // Timings in IOS Format
      day: selectedDate, // selected date according to day
    });
    let sortAllAvailalbeTimingsForDay = _.sortBy(allAvailableTimingsForDay, (o) => { return o.day});

    // console.log("---- sortAllAvailalbeTimingsForDay ---- ", sortAllAvailalbeTimingsForDay);

    this.setState({
      allAvailableTimingsForDay: sortAllAvailalbeTimingsForDay,
    });
  };

  findAvailableNurseData = (startTime, currentBooking) => {
    // console.log("StartTime: ", startTime);
    // console.log("Current Booking INfo: ", currentBooking);
    // console.log("Nurse data: ", this.state.allProviders);
    let availableNurseData = [...this.state.allProviders];
    if(!this.state.isAllProvidersOptionSelected){
      availableNurseData = [this.state.selectedNurseData];
    }
    
    currentBooking.map((value, index) => {
      let nurseIndex = _.findIndex(availableNurseData, (o) => { return (o._id === value.provider) } );
      // console.log("Got the index: ", nurseIndex);
      if(nurseIndex > -1){
        availableNurseData.splice(nurseIndex, 1);
      }
    });
    // console.log("Finally Available Nurse ------------ ", availableNurseData);
    return availableNurseData;
  }

  // addIntervalTimings_backup_2 = (openingTime, closingTime, selectedDate, dayName) => {
  //   const currentDate = moment(selectedDate).format("DD/MM/YYYY");

  //   const selectedDateBookingData = _.filter(
  //     this.state.bookedAppointmentData,
  //     (o) => {
  //       return moment(o.scheduledDate).format("DD/MM/YYYY") === currentDate;
  //     }
  //   );

  //   const data = {
  //     slotInterval: 15,
  //     openTime: moment(openingTime).format("HH:mm"),
  //     closeTime: moment(closingTime).format("HH:mm"),
  //   };

  //   //Format the time
  //   const startTime = moment(data.openTime, "HH:mm");
  //   const endTime = moment(data.closeTime, "HH:mm");

  //   let patientPreferenceTimeStart = startTime,
  //     patientPreferenceEndTime = endTime;

  //   if (this.state.preferedTime === "morning") {
  //     patientPreferenceEndTime = moment("11:59", "HH:mm");
  //   } else if (this.state.preferedTime === "afternoon") {
  //     patientPreferenceTimeStart = moment("12:00", "HH:mm");
  //     patientPreferenceEndTime = moment("16:59", "HH:mm");
  //   } else if (this.state.preferedTime === "evening") {
  //     patientPreferenceTimeStart = moment("17:00", "HH:mm");
  //   }

  //   //Times
  //   let allTimes = [];

  //   while (startTime < endTime) {
  //     let isTimeSuitable =
  //       patientPreferenceTimeStart <= startTime &&
  //       patientPreferenceEndTime >= startTime;
  //     let startTimeBooked = selectedDateBookingData.filter((bookingData) => {
  //       let bookingStartTime = moment(bookingData.scheduledStartTime).format(
  //           "HH:mm"
  //         ),
  //         bookingEndTime = moment(bookingData.scheduledEndTime).format("HH:mm");
  //       let isBookingTimeInBetween =
  //         moment(bookingStartTime, "HH:mm") <= startTime &&
  //         moment(bookingEndTime, "HH:mm") > startTime;
  //       if (isBookingTimeInBetween) return bookingData;
  //     });
  //     if (isTimeSuitable) {
  //       let selectedTimeBookedLenMatch = (startTimeBooked.length === this.state.allProviders.length);

  //       if(!startTimeBooked.length || (this.state.isAllProvidersOptionSelected && !selectedTimeBookedLenMatch)){
  //         let currentStartTime = moment(
  //           currentDate + " " + startTime.format("HH:mm"),
  //           "DD/MM/YYYY HH:mm"
  //         ).toISOString();
  //         allTimes.push(currentStartTime);
  //       }
  //     }
  //     //Add interval of 30 minutes
  //     startTime.add(data.slotInterval, "minutes");
  //   }

  //   let allAvailableTimingsForDay = [...this.state.allAvailableTimingsForDay];
  //   allAvailableTimingsForDay.push({
  //     dayName: dayName, // MON/TUE/...
  //     times: allTimes, // Timings in IOS Format
  //     day: selectedDate, // selected date according to day
  //   });
  //   let sortAllAvailalbeTimingsForDay = _.sortBy(allAvailableTimingsForDay, (o) => { return o.day});
    
  //   this.setState({
  //     allAvailableTimingsForDay: sortAllAvailalbeTimingsForDay,
  //   });
  // };

  // addIntervalTimings_backup = (openingTime, closingTime, selectedDate, dayName) => {
  //   const currentDate = moment(selectedDate).format("DD/MM/YYYY");

  //   const selectedDateBookingData = _.filter(
  //     this.state.bookedAppointmentData,
  //     (o) => {
  //       return moment(o.scheduledDate).format("DD/MM/YYYY") === currentDate;
  //     }
  //   );

  //   const data = {
  //     slotInterval: 15,
  //     openTime: moment(openingTime).format("HH:mm"),
  //     closeTime: moment(closingTime).format("HH:mm"),
  //   };

  //   //Format the time
  //   const startTime = moment(data.openTime, "HH:mm");
  //   const endTime = moment(data.closeTime, "HH:mm");

  //   let patientPreferenceTimeStart = startTime,
  //     patientPreferenceEndTime = endTime;

  //   if (this.state.preferedTime === "morning") {
  //     patientPreferenceEndTime = moment("11:59", "HH:mm");
  //   } else if (this.state.preferedTime === "afternoon") {
  //     patientPreferenceTimeStart = moment("12:00", "HH:mm");
  //     patientPreferenceEndTime = moment("16:59", "HH:mm");
  //   } else if (this.state.preferedTime === "evening") {
  //     patientPreferenceTimeStart = moment("17:00", "HH:mm");
  //   }

  //   //Times
  //   let allTimes = [];

  //   while (startTime < endTime) {
  //     let isTimeSuitable =
  //       patientPreferenceTimeStart <= startTime &&
  //       patientPreferenceEndTime >= startTime;
  //     let startTimeBooked = selectedDateBookingData.filter((bookingData) => {
  //       let bookingStartTime = moment(bookingData.scheduledStartTime).format(
  //           "HH:mm"
  //         ),
  //         bookingEndTime = moment(bookingData.scheduledEndTime).format("HH:mm");
  //       let isBookingTimeInBetween =
  //         moment(bookingStartTime, "HH:mm") <= startTime &&
  //         moment(bookingEndTime, "HH:mm") > startTime;
  //       if (isBookingTimeInBetween) return bookingData;
  //     });
  //     if (isTimeSuitable && !startTimeBooked.length) {
  //       let currentStartTime = moment(
  //         currentDate + " " + startTime.format("HH:mm"),
  //         "DD/MM/YYYY HH:mm"
  //       ).toISOString();
  //       allTimes.push(currentStartTime);
  //     } else {
  //       // console.log(
  //       //   "Excluding this time: ",
  //       //   moment(
  //       //     currentDate + " " + startTime.format("HH:mm"),
  //       //     "DD/MM/YYYY HH:mm"
  //       //   ).format("HH:mm")
  //       // );
  //     }
  //     //Add interval of 30 minutes
  //     startTime.add(data.slotInterval, "minutes");
  //   }

  //   let allAvailableTimingsForDay = [...this.state.allAvailableTimingsForDay];
  //   allAvailableTimingsForDay.push({
  //     dayName: dayName, // MON/TUE/...
  //     times: allTimes, // Timings in IOS Format
  //     day: selectedDate, // selected date according to day
  //   });
  //   let sortAllAvailalbeTimingsForDay = _.sortBy(allAvailableTimingsForDay, (o) => { return o.day});
    
  //   this.setState({
  //     allAvailableTimingsForDay: sortAllAvailalbeTimingsForDay,
  //   });
  // };

  componentDidMount() {
    // console.log("In Online Booking : ", window.location.href);
    const url = window.location.href.split('?')[0];
    let dataToGet = {
      url: url,
    };
    const apiCallParam = {
      reqType: "POST",
      reqURL: getHeadClinicAllData,
      dataObj: dataToGet,
      params: {},
      headers: {},
      successCallback: async (res) => {
        // console.log("Got the clinic data ", res);
        if (!res.data.length) {
          this.setState({
            isInvalidURL: true,
          });
        } else {
          await this.props.setHeadClinicData(res.data[0]);
        }
        this.setState({
          loading: false,
        });
      },
      failureCallback: (err) => {
        console.log("Something went wrong. Please try again ", err);
        this.setState({
          loading: false,
        });
      },
      showPopUp: false,
    };
    this.componentApiCall(apiCallParam);

    this.getContants();
  }

  getContants() {
    const apiCallParam = {
      reqType: "GET",
      reqURL: getConstantsApi,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: async (res) => {
        console.log("Got the constant data: ", res);
        if (res.data && res.data[0]) {
          await this.props.setConstantData(res.data[0]);
        }
      },
      failureCallback: (err) => {

      },
      showPopUp: false,
    };
    this.setState({
      apiCall2: apiCallParam,
    });
  }

  openCallClinicModal = () => {
    this.setState({
      isCallClinicModal: true
    })
  }

  handleGloblaStyleLoaded = (val) => {
    this.setState({
      globalStyleLoaded: val,
    });
  };

  render() {
    return (
      <PublicLayout>
        <GlobalStyle onLoad={this.handleGloblaStyleLoaded} />
        <Loader loading={this.state.loading} />
        {this.state.globalStyleLoaded && (
          <Fragment>
            {this.props.comReducerOnlineBooking?.headClinicData && (
              <div className="p-5 mt-5 container clinic-online-booking">
                <div className="row p-3 pb-4 mb-5 card">
                  <div className="col-sm-12 border-bottom">
                    <Label title="Online Booking" className="big ml-3" />
                  </div>
                  <div className="col-sm-12 mt-4">
                    <div className="row p-0 m-0">
                      {this.props.comReducerOnlineBooking?.headClinicData && (
                        <OnlineBookingForm
                          comReducerOnlineBooking={
                            this.props.comReducerOnlineBooking
                          }
                          doAPICall={this.componentApiCall}
                          onFormSubmit={this.findNursesAvabilities}
                          setNurseData={(data) =>{
                            console.log("Selected --- ", data);
                            this.setState({ selectedNurseData: data });
                              this.setState({
                                isAllProvidersOptionSelected: data._id === "0" ? true : false
                              });
                          }
                          }
                          nurseList={(list) => {
                            this.setState({ allProviders: list});
                          }}
                        />
                      )}
                    </div>
                  </div>

                    <div ref={this.state.bookingAvailibilityRef} className="col-sm-9 mt-5 mx-auto booking-availibility-list-container">
                    {/* <BookingAvailabilityList
                        availableTimes={this.state.allAvailableTimingsForDay}
                        selectedNurseData={this.state.selectedNurseData}
                        selectedSubClinic={this.state.selectedSubClinic}
                        selectedService={this.state.selectedService}
                        selectedTreatment={this.state.selectedTreatment}
                        nurseShifsList={this.state.nurseShifsList}
                        clinicPhone={
                          this.props.comReducerOnlineBooking?.headClinicData
                            ?.mobileNo
                        }
                      /> */}

                    <BookingAvailabilityListByNurse
                      arrangedShiftsData={this.state.arrangedShiftsData || []}
                      selectedSubClinic={this.state.selectedSubClinic}
                      selectedService={this.state.selectedService}
                      selectedTreatment={this.state.selectedTreatment}
                      clinicPhone={
                        this.props.comReducerOnlineBooking?.headClinicData
                          ?.mobileNo
                      }
                      treatmentDuration={this.state.treatmentDuration}
                      openCallClinicModal={this.openCallClinicModal}
                      />
                    </div>

                  {this.state.arrangedShiftsData === 0 &&
                    this.state.bookedAppointmentData && (
                      <div className="col-sm-9 mt-5 mx-auto">
                        <Label
                          title="No Bookings Available"
                          className="w-100"
                        />
                        <Label
                          title={`Please call us on ${this.props.comReducerOnlineBooking?.headClinicData?.mobileNo}`}
                          className="mt-2 w-100"
                        />
                      </div>
                    )}
                </div>
              </div>
            )}
            <MakeAPICall apiParam={this.state.apiCall} />
            <MakeAPICall apiParam={this.state.apiCall2} />
            <CustomPopUp
              openModal={this.state.isInvalidURL}
              title="Instant Touch"
              cancelText="Okay"
              cancelEvent={() => {
                console.log("Clinic Data Not Found!");
              }}
              useOneButton={true}
              modalBodyContent={
                <div className="container-fluid">
                  <Label title="Clinic Data Not Found" />
                </div>
              }
            />
            <CustomPopUp
              openModal={this.state.isCallClinicModal}
              title="Instant Touch"
              cancelText="Okay"
              cancelEvent={() => {
                console.log("Clinic Data Not Found!");
                this.setState({
                  isCallClinicModal: false
                })
              }}
              useOneButton={true}
              modalBodyContent={
                <div className="container-fluid">
                  <Label title={`Please call us on ${this.state.selectedSubClinic ? this.state.selectedSubClinic.mobileNo : ''}`} />
                </div>
              }
            />
          </Fragment>
        )}
      </PublicLayout>
    );
  }
}

// export default withRouter(OnlineBooking);
const mapStateToProps = (state) => ({
  comReducerOnlineBooking: state.comReducerOnlineBooking,
});

const mapDispatchToProps = (dispatch) => ({
  setHeadClinicData: (data) => dispatch(setHeadClinicData(data)),
  setConstantData: (data) => dispatch(setConstantData(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnlineBooking));
