import APP_CONTANTS from "../constants";

const apiRoute = APP_CONTANTS.apiUrl;

// Auth routes
export const loginApi = `${apiRoute}/auth`;
export const loginApiRoute = `${apiRoute}/doctor/loginDoctor`;
export const login = `${apiRoute}/doctor/loginDoctor`;
export const logoutApiRoute = `${apiRoute}/doctor/logoutFromAdmin`;

//Common APIs

/**
 * File Upload
 * Body: Form Data
 * folder: ex. Clinic
 * file: File
 */

export const singleFileUploadApi = `${apiRoute}/file/single`;

/**
 * File Upload Multiple
 * Body: Form Data
 * folder: ex. Clinic
 * file: File
 */

export const multipleFileUploadApi = `${apiRoute}/file/multiple`;

/**
 * File Access from AWS
 * file: File
 */
export const getFileAccess = `${apiRoute}/file/get`;

//Clinic APIs

/**
 * Clinic Add
 * method: POST
 */

export const addClinicApi = `${apiRoute}/clinic/add`;

/**
 * Clinic Add
 * method: POST
 */

export const editClinicApi = `${apiRoute}/clinic/edit`;

/**
 * Clinic exports
 * method: GET
 */

export const clinicExportApi = `${apiRoute}/clinic/exports`;

/**
 * Clinic status delete
 * method: POST
 */

export const clinicDeleteApi = `${apiRoute}/clinic/status`;

/**
 * Clinic list
 * method: GET
 */

export const clinicsListApi = `${apiRoute}/clinic/headquarters`;

/**
 * API: /user/
 */
export const addUserApi = `${apiRoute}/user`;
export const getUsersApi = `${apiRoute}/user`;
export const updateUserApi = `${apiRoute}/user`;
export const HPIIValidationApi = `${apiRoute}/user/hpii`;
export const userCSVExportsApi = `${apiRoute}/user/csv-exports`;
export const getOneUserByIdApi = id => `${apiRoute}/user/${id}`;
export const updateUserByIdApi = id => `${apiRoute}/user/${id}`;
export const updateBankDetails = id => `${apiRoute}/user/${id}/bank-details`;
export const editProfileAPI = `${apiRoute}/user/profile/edit`;
/**
 * Add New patient with Booking
 * POST: /patient/booking
 * fields: {firstName, lastName, email, mobileNo, clinicId, headClinicId}
 */
export const addNewPatientBooking = `${apiRoute}/patient/booking`;

/**
 * Search Patients
 * POST: /patient/search
 * fields: {patientId, searchTerm, isDeleted}
 */
export const getPatientApi = `${apiRoute}/patient/search`;

/**
 * Edit patient details
 * POST: /patient/edit
 * fields: {
 * firstName, lastName, dob, gender, mobileNo, email, ecName, ecNo, address: { line1, line2}, preferedCommMethod, preferedCommTime, marketingLeadSource, marketingPromotionalOptOut, profilePic, notes, isDeleted, defaultHeadClinicId, defaultClinicId, _id}
 */
export const editPatientApi = `${apiRoute}/patient/edit`;

/**
 * Add New patient Route
 * POST: /patient/add
 * fields: {firstName, lastName, dob (iso format), gender [male, female], mobileNo, email, ecName, ecNo, address: {unitStreetNameNumber, suburb, state [All in Caps], postCode}, preferedCommMethod [Call, Email], preferedCommTime[1,2,3,4], marketingLeadSource, marketingPromotionalOptOut[true, false], profilePic, notes, isDeleted [true, false, default: false], isAddBooking [true, false], defaultHeadClinicId, defaultClinicId}
 *
 */
export const addPatientApi = `${apiRoute}/patient/add`;

/**
 * Patient status delete
 * method: POST
 */

export const patientDeleteApi = `${apiRoute}/patient/status`;

/**
 * Description: Search Booking
 * Type: POST
 * URL: /booking/search
 * fields: {bookingId, clinicId, headClinicId, serviceType, provider, treatments, scheduledStartDate, scheduledEndDate, bookingStatus, isDeleted }
 */
export const getBookingApi = `${apiRoute}/booking/search`;

/**
 * Description: Search Bookings with extra filter like provider name and clinic name search
 * Type: POST
 * Url: '/booking/search/extra
 * fields: {isDeleted, serviceType, treatments, scheduledStartDate, scheduledEndDate, bookingStatus, searchTerm(firstName, lastName, middleName, clinic name, clinic email), searchBy[clinic, provider]}
 */
export const getBookingFilterApi = `${apiRoute}/booking/search/extra`;

/**
 * Type: POST
 * Url: /booking/add
 * jsonBody: patientId, clinicId,headClinicId
 */
export const addExistingPatientBooking = `${apiRoute}/booking/add`;

/**
 * Booking edit
 * method: POST
 */
export const bookingEditApi = `${apiRoute}/booking/edit`;

/**
 * Booking status delete
 * method: POST
 */
export const bookingDeleteApi = `${apiRoute}/booking/delete`;

/**
 * Get provider list
 * Type: POST
 * Url: '/user/providers'
 * fields: {clinicId}
 */
export const getProviderListApi = `${apiRoute}/user/providers`;

/**
 * Add Payment Transaction
 * Type: POST
 * URL: /booking/payment/add
 * fields: {bookingId, createdDate, paymentAmount, paymentType, receiptedBy, isReceived}
 */
export const updateBookingTransactionApi = `${apiRoute}/booking/payment/add`;

/**
 * Delete payment transaction
 * Type: POST
 * URL: /booking/payment/delete
 * fields: {paymentId, bookingId, isDeleted}
 */
export const deleteBookingTransaction = `${apiRoute}/booking/payment/delete`;

/**

 * API: /stock/
 */
export const addStockApi = `${apiRoute}/stock`;
export const getStocksApi = `${apiRoute}/stock`;
export const updateStockApi = `${apiRoute}/stock`;
export const getOneStockByIdApi = _id => `${apiRoute}/stock/${_id}`;
export const updateStockChangeLogApi = _id =>
  `${apiRoute}/stock/${_id}/change-log`;
export const stockCSVExportsApi = `${apiRoute}/stock/csv-exports`;
export const stockChangeLogsCSVExportsApi = _id =>
  `${apiRoute}/stock/${_id}/change-log/csv-exports`;

/**
 * Create S4 Drug Protocol
 * Type: POST
 * Url: /booking/create/s4drugprotocol
 * field: {protocols [{protocolName, treatmentType, drugName, area, qty,illustrationImage}], notes: [{note, addedBy}], patientId}
 */
export const addS4DrugProtocolApi = `${apiRoute}/booking/create/s4drugprotocol`;

/**
 * Get S4 Drug Protocol
 * Type: GET
 * URL: /booking/get/s4drugprotocol
 * fields: {patientId, doctorId, status, isDeleted, limit, skip}
 */
export const getS4DrugProtocolApi = `${apiRoute}/booking/get/s4drugprotocol`;

/**
 * Add Treatments to S4 Drug Protocol
 * Type: POST
 * URL: /booking/add/s4drugprotocol/treatment
 * fields: {s4DrugProtocolId (main id from list), createdDate, protocolName (id from protocol list), beforePhotos (array of urls), note, expiredDate (12 month from created date), qty, drugName, measure, area, , treatmentType}
 */
export const addS4TreatmentApi = `${apiRoute}/booking/add/s4drugprotocol/treatment`;

/**
 * Delete Treatment from S4 Drug Protocol
 * Type: POST
 * URL: /booking/delete/s4drugprotocol/treatment
 * fields: {s4DrugProtocolId (main id from list), treatmentId (id from treatments array)}
 */
export const deleteS4TreatmentApi = `${apiRoute}/booking/delete/s4drugprotocol/treatment`;

/**
 * Create Concent Form (once save the s4 Drug Protocol with provider and notes then it will create a new consent form which has to be sign by doctor)
 * Type: POST
 * URL: /booking/add/s4drugprotocol/consentform
 * fields: {s4DrugProtocolId (main _id from list), doctorId, note{addedBy, note}, patientId, createdBy (nurse id), headClinic,subClinic}
 */

export const completeS4DrugProtocolApi = `${apiRoute}/booking/add/s4drugprotocol/consentform`;

/**
 * Get S4 Drug Protocol With Extra search
 * Type: GET
 * URL: /booking/get/s4drugprotocol/search
 * fields: {subClinic, status, isDeleted, searchTerm, searchBy [createdBy, doctor, treatmentType, drugName], createdDate}
 */
export const getS4DrugProtocolExtraSearchApi = `${apiRoute}/booking/get/s4drugprotocol/search`;

/**
 * Delete S4 Drug Protocol (main)
 * Type: POST
 * URL: /booking/delete/s4drugprotocol
 * fields: {s4DrugProtocolId  (main _id from list), isDeleted}
 */
export const deleteS4DrugProtocolApi = `${apiRoute}/booking/delete/s4drugprotocol`;

/**
 * Get Doctor list from subclinic
 * Type: GET
 * URL: /user/doctor
 * fields: {headClinicId, subClinicId, doctorId}
 */
export const getDoctorListApi = `${apiRoute}/user/doctor/search`;

/**
 * Get Consent Forms
 * Type: GET
 * URL: /practitionerconsent
 * fields: {patientId}
 */
export const getPractitionerConsentFormsApi = `${apiRoute}/practitionerconsent`;
export const getPractitionerConsentFormsApiForPaymentSummaryApi = `${apiRoute}/practitionerconsent/for-payment-summary`;

/**
 * Delete Consent Forms
 * Type: POST
 * URL: /practitionerconsent/delete
 * fields: {practitionerConsentId, isDeleted}
 */
export const deletePractitionerConsentFormsApi = `${apiRoute}/practitionerconsent/delete`;

/**
 * Update Practitioner Consent Form
 * Type: POST
 * URL: /practitionerconsent/update
 * fields: {practitionerConsentId, doctorId, headClinic, subClinic, scriptDate, expiredDate}
 */
export const updatePractitionerConsentFormsApi = `${apiRoute}/practitionerconsent/update`;

/**
 * Get Constants
 * Type: GET
 * URL: /admin/constants
 */
export const getConstantsApi = `${apiRoute}/admin/constants`;

/**
 * Update Constants
 * Type: POST
 * URL: /admin/constants
 * fields: {adminServiceAgreement, adminVerificationProcess, adminPrivacyPolicy, doctorServiceAgreement, doctorVerificationProcess, doctorPrivacyPolicy, clinicServiceAgreement, clinicVerificationProcess, clinicPrivacyPolicy,}
 */
export const updateConstantsApi = `${apiRoute}/admin/constants`;

/**
 * get medical history
 * TYPE: GET
 * URL: /medicalhistory
 * fields: {patientId, headClinicId, subClinicId, createdBy, status, createdAt, isDeleted}
 */
export const getMedicalHistoryApi = `${apiRoute}/medicalhistory`;

/**
 * Add new medical history
 * TYPE: POST
 * URL: /medicalhistory
 * fields: {patientId, headClinicId, subClinicId, createdBy, questions [ {que, ans, description} ], status, completedDate, expiredDate, warningCount, patientSignature, createdDate, nurseName, nurseQualification, patientName, patientUIN, patientDOB, patientAddress, clinicName, clinicAddress}
 */
export const addMedicalHistoryApi = `${apiRoute}/medicalhistory`;

/**
 * delete medical history
 * TYPE: POST
 * URL: /medicalhistory/delete
 * fields: { medicalHistoryId, isDeleted }
 */
export const deleteMedicalHistoryApi = `${apiRoute}/medicalhistory/delete`;

/**
 * Get Consent Forms
 * Type: GET
 * URL: /patient/consentform
 * fields: {patientConsentFormId, createdBy, subClinic, patientId, startDate, endDate}
 */
export const getConsentFormApi = `${apiRoute}/patient/consentform`;

/**
 * Get Consent Forms
 * Type: POST
 * URL: /patient/consentform
 * fields: {createdBy, headClinic, subClinic, patientId, note, patientConsentFormPDF}
 */
export const submitConsentFormApi = `${apiRoute}/patient/consentform`;

/**
 * Delete Practitioner Consent Form
 * Type: POST
 * URL: /patient/consentform/delete
 * fields: {patientConsentFormId}
 */
export const deleteConsentFormApi = `${apiRoute}/patient/consentform/delete`;

/**
 * Check patient warning sign on PR
 * TYPE: POST '/patient/check/warningsign'
 * body: {patientId}
 */
export const checkPatientWarningsApi = `${apiRoute}/patient/check/warningsign`;

/**
 * URL: /reminder/manual
 * TYPE: POST
 * fields: {bookingStatus, subClinicId, emailToPatient, smsToPatient, templateWording, text}
 */
export const sendManualRemindersAPI = `${apiRoute}/reminder/manual`;


// =============== For Onine Booking =========== OPEN APIs ==================// =============== For Onine Booking =========== OPEN APIs ==================// =============== For Onine Booking =========== OPEN APIs ==================
// =============== For Onine Booking =========== OPEN APIs ==================// =============== For Onine Booking =========== OPEN APIs ==================// =============== For Onine Booking =========== OPEN APIs ==================

/**
 * Get HeadClinic data for online booking
 * TYPE: POST
 * body: url
 * API: /clinic/headclinic/data
 */
export const getHeadClinicAllData = `${apiRoute}/clinic/headclinic/data`;

/**
 * Get Nurse List based on sub clinic
 * TYPE: POST
 * URL: /user/nurse
 * fields: {selectedSubClinicId}
 */
export const getNurseListAPI = `${apiRoute}/user/nurse`;


/**
 * Online Booking API - OPEN API
 * Type: POST
 * URL: /booking/check/online
 * fields: {preferedDays, provider, serviceType, subClinic, timing, treatment}
 */
export const checkOnlineBookingAvailibilityAPI = `${apiRoute}/booking/check/online`;

/**
 * * NEW Online Booking API - OPEN API
 * Type: POST
 * URL: /booking/nurse/avaibilities
 * fields: {preferedDays,  serviceType, subClinic, treatment}
 */
export const checkNurseAvabilityOnlineBookingAPI = `${apiRoute}/booking/nurse/avaibilities`;



/**
 * Book an appointment through online - OPEN API
 * TYPE: POST
 * URL: /booking/online
 * fields: {clinicId, headClinicId, provider, scheduledDate, scheduledStartTime, serviceType, treatments}
 */
export const bookAppointmentOnlineAPI = `${apiRoute}/booking/online`;

/**
 * Get Booking Deatils To Confirm by patient - OPEN API
 * TYPE: POST
 * URL: /booking/confirm/online
 * fields: {bookingId}
 */
export const bookingDetailsAPI = `${apiRoute}/patient/booking/confirmbypatient`;

/**
 * Get HeadClinic data for online booking
 * TYPE: POST
 * body: url
 * API: /clinic/headclinic/data
 */
export const getHeadClinicAllDataFromPriceListURL = `${apiRoute}/clinic/headclinic/data/pricelist`;

/**
 * Submit Price List Form Data by Patient
 * TYPE: POST
 * body: {formdata}
 * API: /clinic/pricelist/submitbypatient
 */
export const submitPriceListFormByPatientAPI = `${apiRoute}/clinic/pricelist/submitbypatient`;