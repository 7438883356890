import React, { Fragment, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Button from "../../components/button";
import { useHistory } from "react-router-dom";
import appRoutes from "../../routes/appRoutes";
import moment from "moment";
import _ from "lodash";

function createData(day, times) {
    return {
        day,
        times: times
    };
}

function Row(props) {
    const { data } = props;
    console.log("Hey here: ", data);
    const [open, setOpen] = React.useState(false);
    const [hasAnySlots, setHasAnySlots] = React.useState(false);

    const [nurseData, setNurseData] = useState([]);

    const gotoConfirmBooking = (data) => {
        console.log("This is the data: ", data);
        props.history.push({
            pathname: appRoutes.bookingConfirmation,
            state: {
                selectedNurseData: data.nurseData,
                selectedSubClinic: props.selectedSubClinic,
                selectedService: props.selectedService,
                selectedTreatment: props.selectedTreatment,
                selectedTime: '',
                selectedDate: props.data.selectedDate,
                data: data,
                treatmentDuration: data.treatmentDuration
            }
        });
    };

    useEffect(() => {
        let hasAnySlots = false;
        console.log("Data here: ", data);
        if (data && data.data) {
            data.data.map((data) => {
                if (data.availableSlots.length) {
                    hasAnySlots = true
                }
            });
            setHasAnySlots(hasAnySlots);

            let nurseList = data.data || [];
            nurseList.sort(function (a, b) {
                if (a.nurseData.firstName < b.nurseData.firstName) {
                    return -1;
                }
                if (a.nurseData.firstName > b.nurseData.firstName) {
                    return 1;
                }
                return 0;
            });
            setNurseData(nurseList);
            console.log("Nurse list in booking avability: ", nurseList);
        }

    }, []);



    return (
        <Fragment>
            <TableRow onClick={() => setOpen(!open)} className="cursor-pointer">
                <TableCell className="open-row-btn">
                    <IconButton size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell scope="row">{moment(data.selectedDate).format("dddd DD/MM/YYYY")}</TableCell>
            </TableRow>
            <TableRow className="provider-timinings-row">
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow className="bg-grey">
                                        <TableCell>Provider</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {
                                        data && data.data && data.data.length ? nurseData.map((data, index) => {

                                                return <TableRow key={index + index + ''}>
                                                    <TableCell>{`${data.nurseData.firstName} ${data.nurseData.lastName}`}</TableCell>
                                                    <TableCell scope="row">
                                                        {
                                                            data.availableSlots.length && data.bookingCount > 0 ? (
                                                                <span className="amber-text">Limited Bookings Available</span>
                                                            ) : ('')
                                                        }
                                                        {
                                                            data.availableSlots.length && data.bookingCount <= 0 ? (
                                                                <span className="success-text">Available</span>
                                                            ) : ('')
                                                        }
                                                        {
                                                            !data.availableSlots.length ? (
                                                                <span className="error bold-text">No Bookings Available</span>
                                                            ) : ('')
                                                        }
                                                    </TableCell>
                                                    <TableCell className="text-right">
                                                        {data.availableSlots.length ? (
                                                            <Button
                                                                title="Book"
                                                                className="insta-button-primary"
                                                                clickEvent={() => gotoConfirmBooking(data)}
                                                            />

                                                        ) : ('')
                                                        }

                                                        {
                                                            !data.availableSlots.length ? (
                                                                <Button
                                                                    title="Call Clinic"
                                                                    className="insta-button-primary"
                                                                    clickEvent={() => props.openCallClinicModal()}
                                                                />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                        }) : ('')

                                    }


                                    {/* <TableRow key={index + i}>
                                      <TableCell>{nurseList.firstName + ' ' + nurseList.lastName}</TableCell>
                                      <TableCell scope="row">
                                          {moment(time.time).format("hh:mm A")}
                                      </TableCell>
                                      <TableCell className="text-right">
                                          <Button
                                              title="Book"
                                              className="insta-button-primary"
                                              clickEvent={() => gotoConfirmBooking(time, nurseList)}
                                          />
                                      </TableCell>
                                  </TableRow> */}

                                    {!hasAnySlots && (
                                        <TableRow>
                                            <TableCell><span className="error bold-text">No Bookings Available</span></TableCell>
                                            <TableCell>{` Please call us on ${props.selectedSubClinic.mobileNo}`}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

export default function BookingAvailabilityList(props) {
    const history = useHistory();
    // const [rows, setRows] = useState([]);
    const [slotData, setSlotData] = useState(props.arrangedShiftsData || []);

    // const isTimeInBetween = (timeToCheck, startTime, endTime) => {
    //     const result = moment(timeToCheck).isBetween(moment(startTime), moment(endTime));
    //     return result;
    // }

    // const isWorkingNurseOnDay = (day, time, nurse) => {
    //     const nurseShiftObjOnDay = _.find(props.nurseShifsList, (o) => { return o.selectedDate == day });
    //     if (nurseShiftObjOnDay && nurseShiftObjOnDay.nurseData.length) {
    //         const nurseShiftData = _.find(nurseShiftObjOnDay.nurseData, (o) => { console.log(o, nurse); return o.providerId === nurse._id });

    //         if (nurseShiftData) {
    //             // check the nurse is working in the time using shift start time and and time
    //             let allowedToShow = isTimeInBetween(time, nurseShiftData.shiftStartTime, nurseShiftData.shiftEndTime);
    //             return allowedToShow;
    //         }
    //     } else {
    //         // Nurse is not working in this time slot
    //         return false;
    //     }
    // }

    // useEffect(() => {
    //     if (props.availableTimes) {

    //         let availableTime = [];
    //         props.availableTimes.map((availableBookingTime) => {

    //             let workingTimes = [];
    //             availableBookingTime.times.map((times) => {
    //                 let nurseTimes = {
    //                     nurses: [],
    //                     time: times.time
    //                 };
    //                 for (let i = 0; i < times.nurses.length; i++) {
    //                     const isWorkingInSlot = isWorkingNurseOnDay(availableBookingTime.day, times.time, times.nurses[i]);

    //                     if (isWorkingInSlot) {
    //                         nurseTimes.nurses.push(times.nurses[i]);
    //                     }
    //                 }

    //                 // if there is no nurse available on that day then don't push it on times so we can ignore that row
    //                 if (nurseTimes.nurses.length) {
    //                     workingTimes.push(nurseTimes);
    //                 }
    //             });



    //             availableTime.push(
    //                 createData(
    //                     moment(availableBookingTime.day).format("dddd DD/MM/YYYY"),
    //                     workingTimes,
    //                 )
    //             );
    //             // console.log("Final booking times: ", availableBookingTime.times, finalBookingTimes.times);
    //         });
    //         setRows(availableTime);
    //     }
    // }, [props.availableTimes]);



    return (
        <TableContainer
            component={Paper}
            className="primary-table-color available-booking-table"
        >
            {


                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>Available Bookings</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.arrangedShiftsData && props.arrangedShiftsData.map((data, index) => {
                                console.log("98098908908908 date: ", data);
                                return <Row key={index} data={data} history={history} {...props} />
                            })
                        }
                    </TableBody>
                </Table>

            }
        </TableContainer>
    );
}
