import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setHeadClinicData } from "../reduxStore/actions/commonAction";
import { Card, CardImg, CardTitle, CardSubtitle } from "reactstrap";
import { useHistory } from "react-router-dom";
import Label from "../components/label";
import PublicLayout from "../containers/Layout/PublicLayout";
import MakeAPICall from "../apiCalls/common";
import { clinicsListApi } from "../apiRoutes";
import DefaultLogo from "../assets/images/logo-small.png";
import Button from "../components/button";
import CONSTANT from "../constants/index";

const ClinicList = (props) => {
  const history = useHistory();
  const [apiCall, setApiCall] = useState(null);
  const [headClinicData, setHeadClinicData] = useState([]);

  const getClinicList = () => {
    let apiCallParam = {
      reqType: "POST",
      reqURL: clinicsListApi,
      dataObj: {},
      params: {},
      headers: {},
      successCallback: (res) => {
        setHeadClinicData(res.data.doc);
      },
      failureCallback: (err) => {
        console.log("Something went wrong. Please try again ", err);
      },
    };
    setApiCall(apiCallParam);
  };

  const gotoClinic = async (clinicData) => {
    console.log("Clinic Details: ", clinicData);
    if (clinicData.url) {
      const splitURL = clinicData.url.split(CONSTANT.bookingUrl)[1];
      await props.setHeadClinicData(clinicData)
      history.push("/" + splitURL);
    }
  };

  useEffect(() => {
    console.log("Props in Home: ", props);
    getClinicList();
  }, []);

  return (
    <PublicLayout>
      <div className="p-5 mt-5 container clinic-online-booking">
        <div className="row p-3 pb-4 mb-5 bg-white rounded">
          <div className="col-sm-12 border-bottom mb-4">
            <Label title="Clinic List" className="big ml-3" />
          </div>
          {headClinicData.map((headClinic, index) => {
            return (
              <div className="col-sm-12 mt-2" key={index}>
                <Card className="pt-3 pb-3">
                  <div className="row p-0 m-0 align-items-center">
                    <div className="col-sm-2">
                      <div className="card-img-container">
                        <CardImg
                          top
                          className="clinic-logo"
                          src={headClinic.logo ? headClinic.logo : DefaultLogo}
                          alt="Card image cap"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <CardTitle tag="h5">{headClinic.clinicName}</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2">
                        <Label
                          title={headClinic.headQuarterAddress}
                          className="w-100"
                        />
                        <Label title={headClinic.mobileNo} className="w-100" />
                      </CardSubtitle>
                    </div>
                    <div className="col-sm-4">
                      <ul>
                        {headClinic.clinics.map((subClinic, index) => {
                          return (
                            <li key={index}>
                              <Label
                                title={subClinic.name}
                                className="w-100 secondary-font mb-0"
                              />
                              <Label
                                title={subClinic.address}
                                className="w-100"
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-sm-2 d-flex justify-content-center align-items-center">
                    <Button clickEvent={() => gotoClinic(headClinic)} title="Book"/>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
        <MakeAPICall apiParam={apiCall} />
      </div>
    </PublicLayout>
  );
}


// export default withRouter(OnlineBooking);
const mapStateToProps = (state) => ({
    comReducerOnlineBooking: state.comReducerOnlineBooking,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    setHeadClinicData: (data) => dispatch(setHeadClinicData(data)),
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClinicList);
  