import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ICCheckbox = props => (
  <FormControlLabel
    className={props.className ? props.className + ' ic-checkbox-control': 'ic-checkbox-control'}
    control={
      <Checkbox
        className="ic-checkbox"
        {...props}
        checked={props.value || false}
      />
    }
    label={props.label}
    labelPlacement={props.labelPlacement || "end"}
  />
);

export default ICCheckbox;
