import React, { Component, Fragment, createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setHeadClinicData } from "../reduxStore/actions/commonAction";
import OnlineBookingForm from "../containers/OnlineBookingForm/OnlineBookingForm";
import BookingAvailabilityList from "../containers/OnlineBookingForm/BookingAvailabilityList";
import PublicLayout from "../containers/Layout/PublicLayout";
import {
    getHeadClinicAllData,
    checkOnlineBookingAvailibilityAPI,
    bookingDetailsAPI,
} from "../apiRoutes";
import MakeAPICall from "../apiCalls/common";
import CustomPopUp from "../components/modalProps";
import Label from "../components/label";
import moment from "moment";
import _ from "lodash";
import Loader from "../components/loader";
import GlobalStyle from "../components/GlobalStyles";
import ATag from "../components/anchor";
class BookingConfirmationPage extends Component {
    WEEK_DAYS = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

    constructor(props) {
        super(props);

        this.state = {
            apiCall: false, // api call
            loading: true,
            isInvalidURL: false, // if booking not found
            bookingData: null,
            subClinicName: "",
            subClinicPhone: "",
            subClinicEmail: "",
            websiteLink: ""
        };
    }


    componentApiCall = (data) => {
        this.setState({
            apiCall: data,
        });
    };

    getBookingData = () => {
        const bookingId = this.props.match.params.id;


        let dataToGet = {
            bookingId: bookingId
        };
        const apiCallParam = {
            reqType: "POST",
            reqURL: bookingDetailsAPI,
            dataObj: dataToGet,
            params: {},
            headers: {},
            successCallback: async (res) => {
                // console.log("Got the clinic data ", res);
                if (!res.data) {
                    this.setState({
                        isInvalidURL: true,
                    });
                } else {
                    await this.props.setHeadClinicData(res.data.headClinicId);
                    this.setState({
                        bookingData: res.data,
                        subClinicName: res.data['clinicId']['name'],
                        subClinicEmail: res.data['clinicId']['clinicEmail'],
                        subClinicPhone: res.data['clinicId']['mobileNo'],
                        websiteLink: res.data['headClinicId']['redirectURL']
                    })
                }
                this.setState({
                    loading: false,
                });
            },
            failureCallback: (err) => {
                console.log("Something went wrong. Please try again ", err);
                this.setState({
                    loading: false,
                });
            },
            // showPopUp: false,
        };
        this.componentApiCall(apiCallParam);
    }

    componentDidMount() {
        if (!this.props.match || !this.props.match.params || !this.props.match.params.id) {
            this.setState({
                isInvalidURL: true
            });
            return
        }

        this.getBookingData();
    }

    handleGloblaStyleLoaded = (val) => {
        this.setState({
            globalStyleLoaded: val,
        });
    };

    render() {
        return (
            <PublicLayout>
                <GlobalStyle onLoad={this.handleGloblaStyleLoaded} />
                <Loader loading={this.state.loading} />
                <div className="p-2 mt-5 container booking-confirm-page text-left">
                    <div className="mt-4">
                        <Label title="Thank you for confirming your booking with us!" className="big mt-3" />
                    </div>
                    <div>
                        <Label title={`If you need to reschedule, please contact the team at ${this.state.subClinicName} as soon as possible`} className="mt-3" />
                    </div>
                    <div className="mt-1">
                        <Label title={`Phone:`} className="float-left" />
                        <ATag
                            title={this.state.subClinicPhone}
                            href={`tel:${this.state.subClinicPhone}`}
                            className="ml-2 thin-font text-primary"
                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="mt-1">
                        <Label title={`Email:`} className="float-left" />
                        <ATag
                            title={this.state.subClinicEmail}
                            href={`tel:${this.state.subClinicEmail}`}
                            className="ml-2 thin-font text-primary"
                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="mt-1">
                        <ATag
                            title={this.state.websiteLink}
                            href={`${this.state.websiteLink}`}
                            className="thin-font"
                        />
                    </div>
                    <div className="mt-4">

                        <Label title={`Note: Cancellation fees may apply (each clinic has their own policies and procedures regarding cancellations)`} className="" />
                    </div>
                </div>
                {/* {this.state.globalStyleLoaded && (
                    <Fragment>
                        {this.props.comReducerOnlineBooking?.headClinicData && (
                            <div className="p-5 mt-5 container clinic-online-booking">
                                <div className="row p-3 pb-4 mb-5 card">
                                    <div className="col-sm-12 border-bottom">
                                        <Label title="Online Booking" className="big ml-3" />
                                    </div>
                                    <div className="col-sm-12 mt-4">
                                        <div className="row p-0 m-0">
                                            {this.props.comReducerOnlineBooking?.headClinicData && (
                                                <OnlineBookingForm
                                                    comReducerOnlineBooking={
                                                        this.props.comReducerOnlineBooking
                                                    }
                                                    doAPICall={this.componentApiCall}
                                                    onFormSubmit={this.checkAvailableTimesForBooking}
                                                    setNurseData={(data) => {
                                                        console.log("Selected --- ", data);
                                                        this.setState({ selectedNurseData: data });
                                                        this.setState({
                                                            isAllProvidersOptionSelected: data._id === "0" ? true : false
                                                        });
                                                    }
                                                    }
                                                    nurseList={(list) => {
                                                        this.setState({ allProviders: list });
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {this.state.allAvailableTimingsForDay.length !== 0 && (
                                        <div ref={this.state.bookingAvailibilityRef} className="col-sm-9 mt-5 mx-auto booking-availibility-list-container">
                                            <BookingAvailabilityList
                                                availableTimes={this.state.allAvailableTimingsForDay}
                                                selectedNurseData={this.state.selectedNurseData}
                                                selectedSubClinic={this.state.selectedSubClinic}
                                                selectedService={this.state.selectedService}
                                                selectedTreatment={this.state.selectedTreatment}
                                                nurseShifsList={this.state.nurseShifsList}
                                                clinicPhone={
                                                    this.props.comReducerOnlineBooking?.headClinicData
                                                        ?.mobileNo
                                                }
                                            />
                                        </div>
                                    )}
                                    {this.state.allAvailableTimingsForDay === 0 &&
                                        this.state.bookedAppointmentData && (
                                            <div className="col-sm-9 mt-5 mx-auto">
                                                <Label
                                                    title="No Bookings Available"
                                                    className="w-100"
                                                />
                                                <Label
                                                    title={`Please call us on ${this.props.comReducerOnlineBooking?.headClinicData?.mobileNo}`}
                                                    className="mt-2 w-100"
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                        
                        <CustomPopUp
                            openModal={this.state.isInvalidURL}
                            title="Instant Touch"
                            cancelText="Okay"
                            cancelEvent={() => {
                                console.log("Clinic Data Not Found!");
                            }}
                            useOneButton={true}
                            modalBodyContent={
                                <div className="container-fluid">
                                    <Label title="Clinic Data Not Found" />
                                </div>
                            }
                        />
                    </Fragment>
                )} */}
                <CustomPopUp
                    openModal={this.state.isInvalidURL}
                    title="Instant Touch"
                    cancelText="Okay"
                    cancelEvent={() => {
                        console.log("Clinic Data Not Found!");
                    }}
                    useOneButton={true}
                    modalBodyContent={
                        <div className="container-fluid">
                            <Label title="Invalid Link" />
                        </div>
                    }
                />
                <MakeAPICall apiParam={this.state.apiCall} />
            </PublicLayout>
        );
    }
}

// export default withRouter(OnlineBooking);
const mapStateToProps = (state) => ({
    comReducerOnlineBooking: state.comReducerOnlineBooking,
});

const mapDispatchToProps = (dispatch) => ({
    setHeadClinicData: (data) => dispatch(setHeadClinicData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BookingConfirmationPage));
