import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = (props) => {
  const [primaryColor, setPrimaryColor] = useState(
    props.comReducerOnlineBooking?.headClinicData?.primaryClinicColor
  );
  const GlobalStyle = createGlobalStyle`
  button.btn.btn-primary, button.insta-button-primary{
    background: ${primaryColor} !important;
    border: 1px solid ${primaryColor} !important;
  }
  button.btn.btn-primary:hover, button.btn.btn-primary:focus{
    color:  #ffffff !important;
    border: 1px solid ${primaryColor} !important;
  }
  button.insta-button-primary:hover, button.insta-button-primary:focus{
    color:  ${primaryColor} !important;
    border: 1px solid ${primaryColor} !important;
  }
  a{
    color: ${primaryColor} !important;
  }
  .MuiRadio-colorSecondary.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked{
    color: ${primaryColor} !important;
  }
  open-row-btn > button {
    background: "#f5f5f5f !important;
    border: 1px solid ${primaryColor} !important;
  }

  .MuiPickersClockPointer-thumb, .MuiInput-underline:after {
    border-color: ${primaryColor} !important;
  }

  .MuiInput-underline:after{
    border-bottom: 2px solid ${primaryColor} !important;
  }
  .MuiInput-underline:after{
    border-bottom-color: ${primaryColor} !important;
  }

  .ic-select .MuiInput-underline:after{
    border-bottom-color: ${primaryColor} !important;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${primaryColor} !important;
  }
 .cus-modal-header{
    background-color: ${primaryColor} !important;
  }
  button.insta-button-icon.only-icon:hover{
    color: ${primaryColor} !important;
    border: 1px solid ${primaryColor} !important;
    background-color: #ffffff !important;
  }
  .primary-loader {
    color: ${primaryColor} !important;
  }
  .user-pic-loader{
    left: -3px !important;
    top: 10px !important;
  }
  .MuiPickersClockPointer-thumb,
  .MuiInput-underline:after {
    border-bottom: 2px solid ${primaryColor} !important;
  }
  
`;

// .custom-modal-container .modal-header{
//   background-color: ${primaryColor} !important;
// }
// .modal-header{
//   background-color: ${primaryColor} !important;
// }
// .modal-footer button{
//   background: #fea889 !important;
//   border: 1px solid #fea889 !important;
// }
// .modal-footer button.insta-button-primary{
//   color: #fea889 !important;
//   border: 1px solid #fea889 !important;
//   background-color: #ffffff !important;
// }
// .ic-file-upload-icon{
//   color: ${primaryColor} !important;
// }

  useEffect(() => {
    setPrimaryColor(
      props.comReducerOnlineBooking?.headClinicData?.primaryClinicColor
    );
  }, [props.comReducerOnlineBooking]);

  useEffect(() => {
    console.log("Global style loaded: ", true, props);
    if (typeof props.onLoad === "function") props.onLoad(true);
  }, []);

  return (
    <div>
      <GlobalStyle />
    </div>
  );
};

// export default GlobalStyles;
const mapStateToProps = (state) => ({
  comReducerOnlineBooking: state.comReducerOnlineBooking,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalStyles);
