import ACTION_TYPE from "../actions/clinicAction";

const initialState = {
  headClinicList: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.SET_HEAD_CLINIC_LIST:
      return {
        ...state,
        headClinicList: action.payload
      };
    default:
      return state;
  }
}
