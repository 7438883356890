/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

const appRoutes = {
  onlineBooking: "/",
  bookingConfirmation: "/confirm-booking",
  patientConfirmBookingPage: "/patient/confirm/booking",
  priceListPage: "/price",
};

export default appRoutes;
