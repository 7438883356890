/*
 * This file is created by Vatsaly Patel
 * This function will be used for any API calls, you have to pass necessary arguments to use this function.
 * You can use call back function for the responce of APIs
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Model from "../components/modal";
import axios from "axios";
import APP_CONTANTS from "../constants";
// import appRoutes from "../routes/appRoutes"; // not used for now

export function MakeAPICall(props) {
  let history = useHistory(); // used for redirection
  const [errorModelOpen, seterrorModelOpen] = useState(false); // error model open - close
  const [sessionOut, setSessionOut] = useState(false); // session timeout error
  const [errorModelBody, seterrorModelBody] = useState(""); // set error model body contents
  const [errorModelTitle, seterrorModelTitle] = useState(""); // set error model title
  const apiRoute = APP_CONTANTS.apiUrl + "/api/"; // api URL
  const [redirectURL, setRedirect] = useState(null); // pass parameter to redirect to another page on model okay click

  // Close error / success model
  const closeErrorModel = () => {
    seterrorModelBody();
    seterrorModelTitle();
    seterrorModelOpen(false);
    setSessionOut(false);
    if (redirectURL) history.push(redirectURL);
  };

  // open error / success model
  const openErrorModel = (title, body) => {
    seterrorModelTitle(title);
    seterrorModelBody(body);
    seterrorModelOpen(true);
  };

  // Parameters : Request type, Request URL, data obj, parameter obj, headers, success callback, failure callback, showPopUp, redirect URL
  const makeRequest = (
    reqType,
    reqURL,
    dataObj,
    params,
    headers,
    successCallback,
    failureCallback,
    showPopUp,
    redirect,
    noPopup
  ) => {
    // Check headers is passed or not [If passed then use that otherwise append authorization header default]
    let appendedHeader = {};
    if (
      Object.entries(headers).length === 0 &&
      headers.constructor === Object
    ) {
      appendedHeader = {
        Authorization:
          "Bearer " +
          (props.comReducerOnlineBooking.auth
            ? props.comReducerOnlineBooking.auth.accessToken
            : "")
      };
    } else {
      appendedHeader = headers;
    }
    axios({
      method: reqType,
      url: reqURL,
      baseURL: apiRoute,
      headers: appendedHeader,
      data: dataObj,
      params: params,
      timeout: 60000,
      onUploadProgress: function(progressEvent) {
        // Do whatever you want with the native progress event
        // console.log("API call on process");
      },
      onDownloadProgress: function(progressEvent) {
        // Do whatever you want with the native progress event
        // console.log("API call on Download process");
      }
    })
      .then(res => {
        if (showPopUp) {
          seterrorModelTitle("Instant Touch");
          seterrorModelBody(res.data.message || "Success");
          seterrorModelOpen(true);
          if (redirect) setRedirect(redirect);
        }
        successCallback(res.data);
      })
      .catch(err => {
        console.log("Checking catch: ", err,  err.response);
        if (err.response && noPopup === false) {
          if (
            err.response.data.code === 401
          ) {
            seterrorModelTitle("Instant Touch");
            seterrorModelBody("Unauthorized Request");
            seterrorModelOpen(true);
          } else if (
            err.response.data.code === 403 
          ) {
            seterrorModelTitle("Instant Touch");
            seterrorModelBody("Session has expired");
            // seterrorModelOpen(true);
            setSessionOut(true);
          } else if(err.response.data.error && err.response.data.error.value && typeof err.response.data.error.value === "string"){
            seterrorModelTitle("Instant Touch");
            seterrorModelBody(
              err.response.data.error.value
            );
            seterrorModelOpen(true);
          } else if(err.response.data.error && err.response.data.error._message){
            seterrorModelTitle("Instant Touch");
            seterrorModelBody(
              err.response.data.error._message
            );
            seterrorModelOpen(true);
          } else {
            if (err.response.data.message) {
              seterrorModelTitle("Instant Touch");
              seterrorModelBody(
                err.response.data.message ||
                  "Please try again. <br/> Please contact Instant Touch Support <br/> on 07 3555 5888 if this issue persists."
              );
              seterrorModelOpen(true);
            }
          }
        } else if(noPopup === false ){
          seterrorModelTitle("Instant Touch");
          seterrorModelBody("Please try again. <br/> Please contact Instant Touch Support <br/> on 07 3555 5888 if this issue persists.");
          seterrorModelOpen(true);
        }
        failureCallback(
          err.response && err.response.data ? err.response.data : err.response
        );
      });
  };

  // Handle API Param on Change
  useEffect(() => {
    if (props.apiParam)
      makeRequest(
        props.apiParam.reqType, // request type GET POST PUT Delete
        props.apiParam.reqURL, // api call url; we also have base url setuped so do accordangly
        props.apiParam.dataObj, // body parameters
        props.apiParam.params, // if you want to pass parameters in get request
        props.apiParam.headers, // if you want to pass extra headers otherwise it will take access token automatically
        props.apiParam.successCallback, // success callback on api call
        props.apiParam.failureCallback, // failure callback on api call
        props.apiParam.showPopUp, // if you want to show success popup
        props.apiParam.redirect ? props.apiParam.redirect : null, // if you want to redirect on modal click
        props.apiParam.noPopup ? true : false  // not to show error popup in any condition; you can do other things in failureCallback funciton
      );
  }, [props.apiParam]);

  

  return (
    <div>
      <Model
        openModal={errorModelOpen}
        modalBodyContent={errorModelBody}
        okayText="Okay"
        cancelText="Okay"
        title={errorModelTitle}
        useOneButton={true}
        okayEvent={closeErrorModel}
        cancelEvent={closeErrorModel}
      />
      <Model
        openModal={sessionOut}
        modalBodyContent={"You are signed out. Please login again."}
        okayText="Okay"
        cancelText="Okay"
        title={"Signed Out"}
        useOneButton={true}
        okayEvent={closeErrorModel}
        cancelEvent={closeErrorModel}
      />
    </div>
  );
}

// get the store
const mapStateToProps = props => ({
  comReducerOnlineBooking: props.comReducerOnlineBooking
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MakeAPICall);
