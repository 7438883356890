const ACTION_TYPE = {
  SET_HEAD_CLINIC_LIST: "SET_HEAD_CLINIC_LIST"
};

export default ACTION_TYPE;

export const setHeadClinicList = payload => ({
  type: ACTION_TYPE.SET_HEAD_CLINIC_LIST,
  payload
});
