// const USER_TYPE = {
//   SUPER_ADMIN: "super_admin",
//   ADMIN: "admin",
//   DOCTOR: "doctor",
//   ADMIN_DOCTOR: "admin_doctor",
//   NURSE: "nurse",
//   PATIENT: "patient"
// };

// const GENDER = {
//   MALE: "male",
//   FEMALE: "female"
// };

// export default {
//   USER_TYPE,
//   GENDER
// };

exports.USER_TYPE = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  DOCTOR: "doctor",
  ADMIN_DOCTOR: "admin_doctor",
  NURSE: "nurse",
  PATIENT: "patient"
};
exports.LEGAL_TYPE = {
  ADMIN: "adminLegal",
  DOCTOR: "doctorLegal"
};

exports.GENDER = {
  MALE: "male",
  FEMALE: "female"
};

exports.STATES = {
  QLD: "QLD",
  SA: "SA",
  WA: "WA",
  VIC: "VIC",
  NT: "NT",
  TAS: "TAS",
  NSW: "NSW",
  ACT: "ACT"
};

exports.COMMUNICATION_METHOD = {
  CALL: "Call",
  EMAIL: "Email"
};

exports.COMMUNICATION_TIME = {
  DURING_BUSINESS_H: 1,
  OUTSIDE_BUSINESS_H: 2,
  ON_WEEKENDS: 3,
  ANYTIME: 4
};

exports.CONVERT_COMMUNICATION_TIME = {
  1: "During Business Hours",
  2: "Outside Business Hours",
  3: "On Weekends",
  4: "Anytime"
};

exports.SERVICE_TYPES = {
  INITIAL_CONSULT: 1,
  TREATMENT: 2,
  POST_TREATMENT: 3
};

exports.BOOKING_STATUS = {
  ALL: 0,
  BOOKED: 1,
  CONFIRMED: 2,
  IN_SERVICE: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  NO_SHOW: 6
};

exports.CONVERT_SERVICE_TYPE = {
  1: "Initial Treatment",
  2: "Treatment",
  3: "Post Treatment"
};

exports.STOCK_STATUS = {
  IN_STOCK: 1,
  OUT_OF_STOCK: 2,
  EXPIRED: 3,
  RECALLED: 4
};

exports.STOCK_CHANGE_TYPE = {
  USE_STOCK: 1,
  UPDATE_STATUS: 2
};

exports.S4_DRUG_PROTOCOL_STATUS = {
  NEW: 1,
  CURRENT: 2,
  EXPIRED: 3
};

exports.CONVERT_S4_DRUG_PROTOCOL_STATUS = {
  1: "NEW",
  2: "CURRENT",
  3: "EXPIRED"
};

exports.CONSENT_FORM_STATUS = {
  OPEN: 1,
  COMPLETED: 2,
  EXPIRED: 3
};

exports.MEDICAL_HISTORY_STATUS = {
  OPEN: 1,
  COMPLETED: 2,
  EXPIRED: 3
};

exports.STOCK_CHANGE_METHOD = {
  TREATMENT: 1,
  MANUALLY: 2,
  AUTOMATICALLY: 3
};

exports.REMINDER_SEND_CRITERIA = {
  UPCOMING_UNCONFIRMED_BOOKING: 1,
  UPCOMING_CONFIRMED_BOOKING: 2,
  RECEIVED_TREATMENT_YESTERDAY: 3
};

exports.HPII_STATUS = {
  ACTIVE: "A",
  DEACTIVATED: "D",
  RETIRED: "R"
};

exports.EMAIL_WARNINGS = ["con", "png", "cn"];