/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import {
  CONSTANT_DATA,
HEAD_CLINIC_DATA
} from "../constants/commonConstant";

const initialState = {
  headClinicData: null, // HEAD CLINIC DATA
  constants: null, // IT constants
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HEAD_CLINIC_DATA:
      return {
        ...state,
        headClinicData: action.payload
      };
    case CONSTANT_DATA:
      return {
        ...state,
        constants: action.payload
      }
    default:
      return state;
  }
}
