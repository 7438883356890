// Don't modify anything except the last testing one's


let APP_CONTANTS = {};

// For new staging
if (window.location.origin.indexOf("staging-booking") > -1) {
  APP_CONTANTS.apiUrl = "https://staging-api.instanttouch.com.au";
  APP_CONTANTS.prUrl = "https://staging-app.instanttouch.com.au/pr/patient";
  APP_CONTANTS.bookingUrl = "https://staging-booking.instanttouch.com.au/";
}
// For new dev
else if (window.location.origin.indexOf("dev-booking") > -1) {
  APP_CONTANTS.apiUrl = "https://dev-api.instanttouch.com.au";
  APP_CONTANTS.prUrl = "https://dev-app.instanttouch.com.au/pr/patient";
  APP_CONTANTS.bookingUrl = "https://dev-booking.instanttouch.com.au/";
}
// for live
else if (window.location.origin.indexOf("booking") > -1) {
  APP_CONTANTS.apiUrl = "https://api.instanttouch.com.au";
  APP_CONTANTS.prUrl = "http://app.instanttouch.com.au/pr/patient";
  APP_CONTANTS.bookingUrl = "https://booking.instanttouch.com.au/";
}
// For testing
else {
  APP_CONTANTS.apiUrl = "http://localhost:4000";
  APP_CONTANTS.prUrl = "http://localhost:3000/pr/patient";
  APP_CONTANTS.bookingUrl = "http://localhost:3001/";
}

export default APP_CONTANTS;
