/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import React, { Component } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FullLogo from "./../../assets/images/InstantTouchLogo.png";
class TopNav extends Component {

  InstantTouchInfo = {
    logo: FullLogo,
    mobileNoTitle: "07 3555 5888",
    mobileNo: "0735555888",
    email: "support@instanttouch.com.au"
  }

  constructor(props) {
    super(props)
  
    this.state = {
       location: this.props.location.pathname,
    };
  }

  render() {
    return (
      <Navbar
        fixed="top"
        color="light"
        light
        className="top-nav row"
      >
        {this.props.comReducerOnlineBooking?.headClinicData && (
        <NavbarBrand className="col-sm-2 offset-sm-1 pl-3">
          <img
            src={this.props.comReducerOnlineBooking.headClinicData.logo}
            alt="Instant Consult Logo"
            className="clinic-logo"
          />
        </NavbarBrand>
        )}
{/* 
        <NavbarText className="col-sm-5 offset-sm-4">
          <Label title="Support" className="mr-2 gray" />
          <i className="fas fa-phone-alt"></i>
          <ATag
            title={this.state.mobileNoTitle}
            href={`tel:${this.state.mobileNo}`}
            className="mr-3 thin-font"
          />
          <div className="w-100 d-block d-lg-none"></div>
          <i className="fas fa-envelope mr-1"></i>
          <ATag
            href={`mailTo:${this.state.email}`}
            className="thin-font"
            title={`${this.state.email}`}
          />
        </NavbarText> */}
      </Navbar>
    );
  }
}

// export default withRouter(TopNav);
const mapStateToProps = (state) => ({
  comReducerOnlineBooking: state.comReducerOnlineBooking,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopNav));

