import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Label from "../../components/label";
import moment from "moment";

import BookingConfirmationForm from "../../containers/OnlineBookingForm/BookingConfirmationForm";
import PublicLayout from "../../containers/Layout/PublicLayout";
import appRoutes from "../../routes/appRoutes";
import MakeAPICall from "../../apiCalls/common";
import ModalProps from "../../components/modalProps";
import { bookAppointmentOnlineAPI } from "../../apiRoutes";
import GlobalStyle from "../../components/GlobalStyles";

class ConfirmOnlineBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: false, // api call
      bookingData: null,
      isBookingSuccess: false,
      isPatientDeletedModal: false,
      isNurseAlreadyBookedModelOpen: false,
    };
  }

  componentDidMount() {
    console.log("Props: ", this.props.location);
    window.scrollTo(0, 0);
    let data = this.props.location.state;
    if (
      !data.selectedNurseData ||
      !data.selectedService ||
      !data.selectedSubClinic ||
      // !data.selectedTime ||
      !data.selectedTreatment
    )
      this.props.history.push(appRoutes.onlineBooking);
    else {
      let obj = {
        providerName:
          data.selectedNurseData.firstName +
          " " +
          data.selectedNurseData.lastName,
        providerId: data.selectedNurseData._id,
        selectedSubClinicName: data.selectedSubClinic.name,
        selectedSubClinicId: data.selectedSubClinic._id,
        headClinicId: data.selectedSubClinic.headClinic,
        selectedDate: data.selectedDate, // this will be pre-selected and has custom date display
        selectedTime: data.selectedTime,
        selectedTreatment: data.selectedTreatment,
        selectedService: data.selectedService,
        selectedSubClinicMobileNo: data.selectedSubClinic.mobileNo
          ? data.selectedSubClinic.mobileNo
          : "",
        data: data.data,
        treatmentDuration: data.treatmentDuration,
        selectedSubClinicData: data.selectedSubClinic
      };
      console.log("Booking Data Obj: ", obj);
      this.setState({
        bookingData: obj,
      });
    }
  }

  handleAddBooking = (formData) => {
    console.log("Booking Confirmation Form : ", formData);
    const scheduledDate = this.state.bookingData.selectedDate;

    let scheduleTimeInString = moment(formData.time).format("HH:mm");
    let scheduledDateInString = moment(scheduledDate).format("DD/MM/YYYY");
    let scheduleTime = moment(`${scheduledDateInString} ${scheduleTimeInString}`, 'DD/MM/YYYY HH:mm').toISOString();

    let dataToSend = {
      clinicId: this.state.bookingData.selectedSubClinicId,
      headClinicId: this.state.bookingData.headClinicId,
      provider: this.state.bookingData.providerId,
      scheduledDate: scheduledDate,
      scheduledStartTime: scheduleTime,
      serviceType: this.state.bookingData.selectedService,
      treatments: [
        {
          treatmentName: this.state.bookingData.selectedTreatment,
          price: 0.0,
        },
      ],
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      additionalInfo: formData.additionalInfo,
      isAddBooking: true,
      imgs: formData.treatmentImages,
    };
    console.log("Data To Send: ", dataToSend);
    let apiCallParam = {
      reqType: "POST",
      reqURL: bookAppointmentOnlineAPI,
      dataObj: dataToSend,
      params: {},
      headers: {},
      successCallback: async (res) => {
        this.setState({
          isBookingSuccess: true,
        });
      },
      failureCallback: (err) => {
        if (err && err.error && err.error.isDeleted) {
          this.setState({
            isPatientDeletedModal: true
          });
        } else if (err && err.message === "Nurse Already Booked") {
          this.setState({
            isNurseAlreadyBookedModelOpen: true
          });
        }
        console.log("Something went wrong. Please try again ", err);
      },
    };
    this.doAPICall(apiCallParam);
  };

  doAPICall = (apiParam) => {
    this.setState({
      apiCall: apiParam,
    });
  };

  render() {
    return (
      <PublicLayout>
        <GlobalStyle />
        <div className="p-5 mt-5 container booking-confirmation-page">
          <div className="row p-3 pb-4 mb-5 card">
            <div className="col-sm-12 border-bottom pl-0">
              <Label title="Confirm Booking" className="big ml-3" />
            </div>
            <div className="col-sm-12 mt-4">
              <div className="row p-0 m-0">
                {this.state.bookingData && (
                  <BookingConfirmationForm
                    onFormSubmit={this.handleAddBooking}
                    bookingData={this.state.bookingData}
                  />
                )}
              </div>
            </div>
          </div>
          <MakeAPICall apiParam={this.state.apiCall} />
          <ModalProps
            openModal={this.state.isBookingSuccess}
            title="Booking Successful"
            cancelText="Okay"
            cancelEvent={() => {
              this.setState({
                isBookingSuccess: false,
              });
              this.props.history.goBack();
              if (this.props.headClinicData.redirectURL) {
                window.open(this.props.headClinicData.redirectURL, "_blank");
              }
            }}
            useOneButton={true}
            modalBodyContent={
              <div className="container-fluid">
                <Label
                  title="Confirmation of your booking has been sent to your email"
                  className="w-100"
                />
                <Label
                  title={`If you need to reschedule, or have any questions regarding your upcoming booking, please call us on ${this.state.bookingData?.selectedSubClinicMobileNo}`}
                  className="w-100 mt-2"
                />
              </div>
            }
          />
           <ModalProps
            openModal={this.state.isPatientDeletedModal}
            title="Warning"
            cancelText="Okay"
            cancelEvent={() => {
              this.setState({
                isPatientDeletedModal: false,
              });
            }}
            useOneButton={true}
            modalBodyContent={
              <div className="container-fluid">
                <Label
                  title="The email address you entered has been deleted in our system"
                  className="w-100"
                />
                <Label
                  title={`Please call us on ${this.state.bookingData?.selectedSubClinicMobileNo} to restore your account and continue with your online booking`}
                  className="w-100 mt-2"
                />
              </div>
            }
          />
          <ModalProps
            openModal={this.state.isNurseAlreadyBookedModelOpen}
            title="Instant Touch"
            cancelText="Okay"
            cancelEvent={() => {
              this.setState({
                isNurseAlreadyBookedModelOpen: false,
              });
              // this.props.history.goBack();
            }}
            useOneButton={true}
            modalBodyContent={
              <div className="container-fluid">
                <Label
                  title="Nurse Already Booked"
                  className="w-100"
                />
                <Label
                  title={`Please select other time slot or call us on ${this.state.bookingData?.selectedSubClinicMobileNo}`}
                  className="w-100 mt-2"
                />
              </div>
            }
          />

        </div>
      </PublicLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  headClinicData: state.comReducerOnlineBooking.headClinicData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfirmOnlineBooking));
