/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import {
  CONSTANT_DATA,
  HEAD_CLINIC_DATA
} from "../constants/commonConstant";

export const setHeadClinicData = data => ({
  type: HEAD_CLINIC_DATA,
  payload: data
});

export const setConstantData = data => ({
  type: CONSTANT_DATA,
  payload: data
});