/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import React, { Fragment } from "react";
import TopNav from "../../TopNavBar";
import Footer from "../../Footer";
import GlobalStyles from "../../../components/GlobalStyles";

const PublicLayout = props => {
  return (
    <Fragment>
      <GlobalStyles />
      <TopNav />
      <div className="container-fluid public-layout-container">
        {props.children}
      </div>
      <Footer />

    </Fragment>
  );
};

export default PublicLayout;
