import React, { Fragment, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "../../components/button";
import Label from "../../components/label";
import ICCheckbox from "../../components/form/checkbox";
import PropTypes from "prop-types";
import ICSelect from "../../components/form/selectInput";
import { getNurseListAPI } from "../../apiRoutes";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { SERVICE_TYPES } from "../../constants/common";
import _, { forEach } from "lodash";
import GlobalStyle from "../../components/GlobalStyles";

const OnlineBookingForm = (props) => {
  const serviceArrayTmp = [
    {
      _id: SERVICE_TYPES.INITIAL_CONSULT,
      val: "Initial Consult",
      price: 200.0,
    },
    { _id: SERVICE_TYPES.TREATMENT, val: "Treatment", price: 300.0 },
    { _id: SERVICE_TYPES.POST_TREATMENT, val: "Post Treatment", price: 250.0 },
  ];

  // let isClinicOpen = ({
  //   "MON": true,
  //   "TUE": true,
  //   "WED": true,
  //   "THU": true,
  //   "FRI": true,
  //   "SAT": true,
  //   "SUN": true
  // });
  const [isClinicOpen, setIsClinicOpen] = useState({
    "MON": true,
    "TUE": true,
    "WED": true,
    "THU": true,
    "FRI": true,
    "SAT": true,
    "SUN": true
  })

  const [isDaysError, setIsDaysError] = useState(false);

  const [nurseList, setNurseList] = useState([]);

  const [treatmentList, setTreatmentList] = useState([]);

  const [subClinicList, setSubClinicList] = useState([]);

  const formik = useFormik({
    initialValues: {
      headClinicId: '',
      subClinic: "Select Clinic",
      // provider: "Select Provider",
      service: "Select Service",
      treatment: "Select Treatment",
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      // radioBtnGroup: "",
    },
    validationSchema: Yup.object({
      subClinic: Yup.string()
        .required("Please select clinic")
        .notOneOf(["Select Clinic"]),
      // provider: Yup.string()
      //   .required("Please select provider")
      //   .notOneOf(["Select Provider"]),
      service: Yup.string()
        .required("Please select service")
        .notOneOf(["Select Service"]),
      treatment: Yup.string()
        .required("Please select treatment")
        .notOneOf(["Select Treatment"]),
      // radioBtnGroup: Yup.string().required("Please select options"),
    }),
    onSubmit: (values) => {
      setIsDaysError(false);
      const DAYS_OF_WEEK = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      let checkDays = [];
      DAYS_OF_WEEK.map((day, index) => {
          if(values[day]){
              checkDays.push(index + 1);
          }
      });
      if (
        values.monday ||
        values.tuesday ||
        values.wednesday ||
        values.thursday ||
        values.friday ||
        values.saturday ||
        values.sunday
      ) {
        let allProviderIds = getAllProviderId();
        // let allProviderIds = [];
        // if(values.provider == "0"){
        //   allProviderIds = getAllProviderId();
        // }else{
        //   allProviderIds = [values.provider];
        // }

        let obj = {
          // provider: values.provider,
          headClinicId: values.headClinicId,
          providers: allProviderIds,
          timing: values.radioBtnGroup,
          subClinic: values.subClinic,
          serviceType: values.service,
          treatment: values.treatment,
          preferedDays: checkDays
        }
        props.onFormSubmit(obj);

      } else {
        setIsDaysError(true);
      }
    },
  });

  const checkOpeningHours = (subClinicId) => {
    let objCopy = Object.assign({}, isClinicOpen);
    for (const key in objCopy) {
      objCopy[key] = true;
    };

    let subClinicData = _.find(props.comReducerOnlineBooking.headClinicData.clinics, (o) => { return o._id === subClinicId});
    if(subClinicData){
      _.map(subClinicData.openingHours, (o) => { 
        objCopy[o.day] = false;               
      });
      setIsClinicOpen(objCopy);
    };
  }

  const getAllProviderId = () => {
    let nurseListArr = [...nurseList];
    nurseListArr.splice(0, 1);
    let providers = _.map(nurseListArr, "_id");
    return providers;
  }

  const resetWeekCheckBox = () => {
    formik.setFieldValue("monday", false);
    formik.setFieldValue("tuesday", false);
    formik.setFieldValue("wednesday", false);
    formik.setFieldValue("thursday", false);
    formik.setFieldValue("friday", false);
    formik.setFieldValue("saturday", false);
    formik.setFieldValue("sunday", false);
  };

  const setHeadClinicId = (subClinicId) => {
    let subClinicData = _.find(props.comReducerOnlineBooking.headClinicData.clinics, (o) => { return o._id === subClinicId });
    if (subClinicData) {
      formik.setFieldValue("headClinicId", subClinicData.headClinic);
    };
  }

  const getNurseList = (subClinicId) => {
    resetWeekCheckBox();
    let dataToSend = {
      selectedSubClinicId: subClinicId,
    };
    let apiCallParam = {
      reqType: "POST",
      reqURL: getNurseListAPI,
      dataObj: dataToSend,
      params: {},
      headers: {},
      successCallback: async (res) => {
        let defaultArr = [{
          _id: "0",
          firstName: "All",
          lastName: "Providers"
        }];
        let nurseList = res.data || [];
        await nurseList.sort(function (a, b) {
          if (a.firstName < b.firstName) {
            return -1;
          }
          if (a.firstName > b.firstName) {
            return 1;
          }
          return 0;
        });
        defaultArr = await defaultArr.concat(nurseList);
        setNurseList(defaultArr);
        props.nurseList(res.data);
        checkOpeningHours(subClinicId);
      },
      failureCallback: (err) => {
        console.log("Something went wrong. Please try again ", err);
      },
    };
    props.doAPICall(apiCallParam);
  };

  const setSelectedProvider = (providerId) => {
    let nurseData = _.find(nurseList, (o) => {
      return o._id === providerId;
    });
    if(nurseData) props.setNurseData(nurseData);
  };

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.href = props.comReducerOnlineBooking.headClinicData.logo;

    let treatmentTypes = props.comReducerOnlineBooking &&
      props.comReducerOnlineBooking.headClinicData
      ? props.comReducerOnlineBooking.headClinicData.treatmentTypes
      : [];

    console.log("Got th treatment types: ", treatmentTypes);
    if (treatmentTypes.length) {
      treatmentTypes.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      console.log("Sorted treatment types: ", treatmentTypes);
      setTreatmentList(treatmentTypes);
    }

    let subClinics = props.comReducerOnlineBooking &&
      props.comReducerOnlineBooking.headClinicData
      ? props.comReducerOnlineBooking.headClinicData.clinics
      : []
    subClinics.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    setSubClinicList(subClinics);

  }, [props.comReducerOnlineBooking]);

  return (
    <Fragment>
      <GlobalStyle />
      {/* <form
        onSubmit={formik.handleSubmit}
        className=" text-left add-clinic-form"
      /> */}

      {/* </form> */}
      <form onSubmit={formik.handleSubmit} className="text-left row p-0 m-0">
        <div className="col-sm-4 mt-3">
          <Label title="Clinic" className="font-15 gray" required={true} />
          <ICSelect
            label=""
            placeholder="Select Clinic"
            id="subClinic"
            name="subClinic"
            useId="_id"
            useValue="name"
            items={
              subClinicList
            }
            value={formik.values.subClinic}
            disabled={props.isReadOnly}
            onBlur={formik.handleBlur}
            handleChange={(val) => {
              formik.setFieldValue("subClinic", val);
              setHeadClinicId(val);
              getNurseList(val);
            }}
          />
          {formik.errors.subClinic && formik.touched.subClinic ? (
            <Label title="Please select clinic" className="small error" />
          ) : (
            ""
          )}
        </div>
        {/* <div className="col-sm-3 mt-3">
          <Label title="Provider" className="font-15 gray" required={true} />
          <ICSelect
            label=""
            placeholder="Select Provider"
            id="provider"
            name="provider"
            useId="_id"
            useValue="firstName"
            useValue2="lastName"
            isCombined={true}
            items={nurseList}
            value={formik.values.provider}
            disabled={props.isReadOnly}
            onBlur={formik.handleBlur}
            handleChange={(val) => {
              formik.setFieldValue("provider", val);
              setSelectedProvider(val);
            }}
          />
          {formik.errors.provider && formik.touched.provider ? (
            <Label title="Please select provider" className="small error" />
          ) : (
            ""
          )}
        </div> */}
        <div className="col-sm-4 mt-3">
          <Label title="Service" className="font-15 gray" required={true} />
          <ICSelect
            label=""
            placeholder="Select Service"
            id="service"
            name="service"
            useId="_id"
            useValue="val"
            items={serviceArrayTmp}
            value={formik.values.service}
            disabled={props.isReadOnly}
            onBlur={formik.handleBlur}
            handleChange={(val) => {
              formik.setFieldValue("service", val);
            }}
          />
          {formik.errors.service && formik.touched.service ? (
            <Label title="Please select service" className="small error" />
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-4 mt-3">
          <Label title="Treatment" className="font-15 gray" required={true} />
          <ICSelect
            label=""
            placeholder="Select Treatment"
            id="rreatment"
            name="treatment"
            useId="name"
            useValue="name"
            items={treatmentList}
            value={formik.values.treatment}
            disabled={props.isReadOnly}
            onBlur={formik.handleBlur}
            handleChange={(val) => {
              formik.setFieldValue("treatment", val);
            }}
          />
          {formik.errors.treatment && formik.touched.treatment ? (
            <Label title="Please select treatment" className="small error" />
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-12 pb-0 mb-0 mt-4">
          <Label title="Please Tick" className="font-15 gray" required={true} />
        </div>
        <div className="col-sm-12 days-checkbox">
          <ICCheckbox
            name="monday"
            id="monday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.monday}
            disabled={isClinicOpen.MON}
            inputProps={{ "aria-label": "" }}
            label="Monday"
          />
          <ICCheckbox
            name="tuesday"
            id="tuesday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.tuesday}
            disabled={isClinicOpen.TUE}
            inputProps={{ "aria-label": "" }}
            label="Tuesday"
          />
          <ICCheckbox
            name="wednesday"
            id="wednesday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.wednesday}
            disabled={isClinicOpen.WED}
            inputProps={{ "aria-label": "" }}
            label="Wednesday"
          />
          <ICCheckbox
            name="thursday"
            id="thursday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.thursday}
            disabled={isClinicOpen.THU}
            inputProps={{ "aria-label": "" }}
            label="Thursday"
          />
          <ICCheckbox
            name="friday"
            id="friday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.friday}
            disabled={isClinicOpen.FRI}
            inputProps={{ "aria-label": "" }}
            label="Friday"
          />
          <ICCheckbox
            name="saturday"
            id="saturday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.saturday}
            disabled={isClinicOpen.SAT}
            inputProps={{ "aria-label": "" }}
            label="Saturday"
          />
          <ICCheckbox
            name="sunday"
            id="sunday"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sunday}
            disabled={isClinicOpen.SUN}
            inputProps={{ "aria-label": "" }}
            label="Sunday"
          />
          {isDaysError && (
            <Label
              title="Please tick day(s)"
              className="error w-100"
              required={true}
            />
          )}
        </div>
        {/* <div className="col-sm-12 pb-0 mb-0 mt-3">
          <Label
            title="Please Select"
            className="font-15 gray"
            required={true}
          />
        </div> */}
        {/* <div className="col-sm-12 time-radio-btns">
          <FormControl
            component="fieldset"
            className="w-100 radio-btn-control"
          >
            <RadioGroup
              aria-label="radioBtnGroup"
              name="radioBtnGroup"
              id="radioBtnGroup"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.radioBtnGroup}
              error={
                formik.touched.radioBtnGroup && formik.errors.radioBtnGroup
                  ? true
                  : false
              }
              helperText={
                formik.touched.radioBtnGroup && formik.errors.radioBtnGroup
                  ? formik.errors.radioBtnGroup
                  : ""
              }
            >
              <FormControlLabel
                value="anytime"
                control={<Radio />}
                label="Anytime"
              />
              <FormControlLabel
                value="morning"
                control={<Radio />}
                label="Morning"
              />
              <FormControlLabel
                value="afternoon"
                control={<Radio />}
                label="Afternoon"
              />
              <FormControlLabel
                value="evening"
                control={<Radio />}
                label="Evening"
              />
            </RadioGroup>
          </FormControl>
          <Label
            title="Please select options"
            className={
              formik.touched.radioBtnGroup && formik.errors.radioBtnGroup
                ? "small error"
                : "d-none"
            }
          />
        </div> */}
        <div className="col-sm-12 mt-4 text-center">
          <Button type="submit" title="Check Availability" className="" />
        </div>
      </form>
    </Fragment>
  );
};

export default OnlineBookingForm;

OnlineBookingForm.defaultProps = {
  serverErrorMsg: "",
};

OnlineBookingForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string,
};
