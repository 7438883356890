/*
 * This file is edited by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import React, { PureComponent, Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

//Online Booking
import OnlineBooking from "../pages/OnlineBooking/OnlineBooking";
import BookingConfirmation from "../pages/OnlineBooking/BookingConfirmation";
import ClinicList from "../pages/ClinicList";
import BookingConfirmationPage from "../pages/BookingConfirmationPage";
import CheckInPriceListPage from "../pages/CheckInPriceList/CheckInPriceListPage";

// Routes
import appRoutes from "./appRoutes"; // nurse routes

class AppRoutes extends PureComponent {
  render() {
    const comReducerOnlineBooking = this.props.comReducerOnlineBooking;
    console.log("Check props: ", this.props);
    console.log("-- auth -- ", comReducerOnlineBooking);
    // let baseName = "clinicName";
    return (
      <div>
        <Router>
          <Switch>
            
              <Route path="/" component={ClinicList} exact />
            <Route path={`${appRoutes.bookingConfirmation}`} component={BookingConfirmation} />
            <Route path={`/:clinicName`} component={OnlineBooking} exact/>
            <Route path={`${appRoutes.patientConfirmBookingPage + '/:id'}`} component={BookingConfirmationPage} />
            <Route path={`/price/:priceListURL`} component={CheckInPriceListPage} exact />
            {/* <Redirect from="*" to="/" /> */}
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  comReducerOnlineBooking: state.comReducerOnlineBooking
});

export default connect(mapStateToProps, null)(AppRoutes);
