import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LoaderSVG from "../assets/images/loader-transparent.svg";

const InstaLoader = props => {
  return (
    <Fragment>
      <div className={props.loading ? "insta-loader" : "d-none"}>
        <img src={LoaderSVG} alt="loader" />
      </div>
    </Fragment>
  );
};
export default InstaLoader;

InstaLoader.defaultProps = {
  loading: false
};

InstaLoader.propTypes = {
  loading: PropTypes.bool
};
